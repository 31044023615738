import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UserPlanDelete = (props) => {
  const { t } = useTranslation();
  
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Downgrade plan warning")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("You are downgrading your plan. Depending on your plan, we are going to deactivate bots, files and URLs!")}
        </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="button" onClick={(e) => { props.callSubscription(e); props.onHide(); }}>
            {t("ok")}
          </button>

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserPlanDelete;
