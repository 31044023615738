import React, { useEffect, useState } from "react";

const Header = ({ onToggleSidebar }) => {
  const name = localStorage.getItem("NAME");
  const [loginUserNAme, setLoginUserNAme] = useState(name);
  useEffect(() => {
    setLoginUserNAme(localStorage.getItem("NAME"));
  }, [name]);

  return (
    <>
      <header className="dashboard-app">
        <div className="dashboard-toolbar">
          <div className="head_wrap">
            <a
              href="javascript:void(0);"
              className="menu-toggle"
              id="toggleSidebarBtn"
              onClick={onToggleSidebar}
            >
              <i className="fas fa-bars"></i>
            </a>
            <a className="brand-logo-mob d-lg-none d-flex" href="/">
              <img src="../../images/ChatDocX_logo.svg" alt="" />
            </a>
            <a href="" className="user_info">
              <i className="fa-solid fa-user-large"></i>
              {loginUserNAme}
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
