import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactTost from "../../ReactTost";
import axios from "axios";
import DeleteUrls from "../Modal/DeleteUrls";
import Loader from "../../Loader";
import MsgLoading from "../../MsgLoading";

const UserChatBotSources = (props) => {
  const [urlError, setUrlError] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [urlId, setUrlId] = useState();
  const [isUrl, setIsUrl] = useState(true);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const botId = props.botData.id;
  const user = localStorage.getItem("id");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const { t } = useTranslation();
  const [urls, setUrls] = useState([""]);
  const [docAll, setDocAll] = useState([]);
  const [urlCount, setUrlCount] = useState(0);

  const handleUrlChange = (index, url, id) => {
    const updatedUrls = [...urls];
    if (id && user) {
      let _temp = {
        user,
        url,
        id,
      };
      updatedUrls[index] = _temp;
    } else {
      updatedUrls[index] = url;
    }
    setUrls(updatedUrls);
  };

  //delete scan url
  const deleteUrlHandler = (index, id = null) => {
    if (id) {
      setUrlId(id);
      setDeleteModalShow(true);
    } else {
      const updatedUrls = urls.filter((_, i) => i !== index);
      setUrls(updatedUrls);
    }
  };

  //getting data of saved document
  useEffect(() => {
    if (props.docData) {
      console.log(props.docData)
      setDocAll(props.docData.filter((data) => data.type !== "txt"));
    }
    axios
      .get(`${API_URL}/${API_VERSION}/bot/${botId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //console.log(response.data.docs)
        const receivedCheckboxIds = response.data.docs;
        setSelectedCheckboxes(receivedCheckboxIds);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [API_URL, API_VERSION, props.docData, botId]);
  //updating the ids and checkboxes of documents
  function updateDoc(event) {
    const checkboxId = event.target.value;
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxId]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== checkboxId)
      );
    }
  }
  //saving documents
  function updateDocument(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/bot/${botId}`,
        {
          docs: selectedCheckboxes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(async (response) => {
        await indexingDocument()
          .then(() => {
            setMessage(t("Selected documents successfully scanned."));
            setType("Success");
          })
          .catch(() => {
            setType("Danger");
            setMessage(t("Documents not save to gpt."));
          });
        setShowAlert(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setType("Danger");
        setMessage(t("Something went wrong."));
        setShowAlert(true);
      });
  }
  //saving scan urls
  function saveScanUrl(e, index, _tempId) {
    e.preventDefault();
    setLoading(true);
    if (!urls[index]) {
      setUrlError(t("Scan URL cannot be empty."));
      setLoading(false);
      return;
    }
    const urlSizeInBytes = new Blob([urls[index]]).size; // Get the size in bytes
    // console.log(urlSizeInBytes);
    const maxSizeInBytes = 500 * 1024; // 500KB in bytes

    if (urlSizeInBytes > maxSizeInBytes) {
      setUrlError(t("URL size exceeds 500KB limit."));
      setLoading(false);
      return;
    }
    checkingPlan()
      .then((_data_) => {
        if (urls[index]) {
          let url = [];
          url.push(urls[index]);
          let isUpdate = 0;
          if (_tempId) {
            isUpdate = _tempId;
          }
          console.log(botId);
          axios
            .post(
              `${API_URL}/${API_VERSION}/url`,
              {
                url,
                user,
              bot:botId,
                isUpdate,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(async (response) => {
              setTimeout(async () => {
                await indexingDocument()
                  .then(() => {
                    setMessage(t("Url scanning success."));
                    setType("Success");
                  })
                  .catch(() => {
                    setType("Danger");
                    setMessage(t("Url not save to gpt."));
                  });
                setShowAlert(true);
                setUrlError("");
                setLoading(false);
              }, 5000);
            })
            .catch((err) => {
              //console.log();
              fetchUrl();
              setLoading(false);
              setType("Danger");
              setMessage(
                t(
                  err.response.data.message
                    ? err.response.data.message
                    : "Something went wrong."
                )
              );
              setShowAlert(true);
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        // console.log(e);
      });
  }

  async function indexingDocument() {
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_URL}/${API_VERSION}/bot/index/${botId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((_response) => {
          fetchUrl();
          resolve(true);
        })
        .catch((_error) => {
          reject(false);
        });
    });
  }

  async function fetchUrl() {
    setLoading(true);
    await axios
      .get(`${API_URL}/${API_VERSION}/url?id=${user}&botId=${botId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(async (response) => {
        setLoading(false);
        console.log(response.data);
        if (response.data) {
          response.data.map((data, index) => {
            const fetchedUrls = response.data.map((item) => item);
            setUrls(fetchedUrls);
          });
          setUrlId(response.data.id);
        } else {
          setUrls([]);
          setUrlId("");
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  }
  useEffect(() => {
    if (isUrl) {
      setIsUrl(false);
      (async () => {
        await Promise.all([fetchUrl(), getUserUrlCount()]);
        //await getUserUrlCount();
      })();
    }
  }, [isUrl]);

  async function getUserUrlCount() {
    setLoading(true);
    await axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        //console.log(response);
        if (response.data) {
          //console.log(response);
          setUrlCount(response.data.plan.maxUrlCount);
        }
      })
      .catch((error) => {
        setLoading(false);
       // console.log(error.status);
        setType("Danger");
        setMessage(t("Something went wrong"));
        setShowAlert(true);
        return false;
      });
  }

  function checkingPlan() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />

      <section className="usertabs">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <Tabs>
                <TabList className="d-flex align-items-center justify-content-center">
                  <Tab>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-list-check"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="#05204a"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
                      <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
                      <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
                      <path d="M11 6l9 0" />
                      <path d="M11 12l9 0" />
                      <path d="M11 18l9 0" />
                    </svg>{" "}
                    {t("Choose files")}
                  </Tab>
                  <Tab>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-scan"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="#05204a"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 7v-1a2 2 0 0 1 2 -2h2" />
                      <path d="M4 17v1a2 2 0 0 0 2 2h2" />
                      <path d="M16 4h2a2 2 0 0 1 2 2v1" />
                      <path d="M16 20h2a2 2 0 0 0 2 -2v-1" />
                      <path d="M5 12l14 0" />
                    </svg>{" "}
                    {t("Scan URL")}
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="option_box">
                    <div
                      class="alert alert-primary d-flex align-items-center"
                      role="alert"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-info-circle-filled"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#05204a"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                          stroke-width="0"
                          fill="currentColor"
                        />
                      </svg>
                      <div className="ms-2">
                        {t("Selected document will be scan")}
                      </div>
                    </div>

                    <h4>{t("Choose Document")}</h4>
                    {docAll &&
                      docAll.map((doc, index) => (

                        <Form.Group
                          className="mb-3"
                          //controlId={index}
                          key={doc.id}
                        > {doc.isDocActive === '1' && (
                          <Form.Check
                            type="checkbox"
                            id={doc.id}
                            label={doc.name}
                            checked={selectedCheckboxes.includes(doc.id)}
                            value={doc.id}
                            onChange={(e) => updateDoc(e)}
                          />
                        )}
                        </Form.Group>

                      ))}
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          onClick={updateDocument}
                          className="userbutton"
                        >
                          {t("save")}
                        </button>
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="option_box">
                    <div
                      class="alert alert-primary d-flex align-items-center"
                      role="alert"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-info-circle-filled"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#05204a"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                          stroke-width="0"
                          fill="currentColor"
                        />
                      </svg>
                      <div className="ms-2">{t("You can scan url here")}</div>
                    </div>
                    {loading ? <Loader /> : ""}
                    {urls && urlCount > 0 && (
                      <>
                        {Array.from({ length: urlCount }, (_, index) => {
                          let _tempUrl = "";
                          let _tempId = "";

                          if (urls[index]) {
                            _tempId = urls[index].id;
                            _tempUrl = urls[index].url;
                          }
                          return (
                            <div
                              key={index}
                              className="d-flex flex-sm-row flex-column align-items-center justify-content-between gap-2 mb-3"
                            >
                              <Form.Control
                                type="url"
                                value={_tempUrl}
                                onChange={(e) =>
                                  handleUrlChange(
                                    index,
                                    e.target.value,
                                    _tempId
                                  )
                                }
                              />
                              <button
                                className="userbutton"
                                onClick={(e) => saveScanUrl(e, index, _tempId)}
                              >
                                {t("Scan")}
                              </button>
                              <Button
                                type="button"
                                variant="danger"
                                onClick={() => deleteUrlHandler(index, _tempId)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Button>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </Container>
        <DeleteUrls
          show={deleteModalShow}
          deleteScanUrlId={urlId}
          // fetch={fetchUrl}
          onHide={() => {
            indexingDocument();
            fetchUrl();
            setDeleteModalShow(false);
          }}
        />
      </section>
    </>
  );
};

export default UserChatBotSources;
