// import React, { useEffect, useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import ReactTost from "../../ReactTost";
// import { useTranslation } from "react-i18next";
// import Loader from "../../Loader";

// const UserUploadDoc = () => {
//   const [loading, setLoading] = useState(false);
//   let user = localStorage.getItem("id");
//   const { t } = useTranslation();
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [name, setName] = useState();
//   const [description, setDescription] = useState();
//   const [messageType, setMessageType] = useState("");
//   const [message, setMessage] = useState("");
//   const [showAlert, setShowAlert] = useState(false);

//   const API_URL = process.env.REACT_APP_API_URL;
//   const API_VERSION = process.env.REACT_APP_API_VERSION;

//   useEffect(() => {
//     setLoading(true);
//     axios
//       .get(`${API_URL}/${API_VERSION}/user_doc/${id}`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       })
//       .then((response) => {
//         setLoading(false);
//         setName(response.data.name);
//         setDescription(response.data.description);
//       })
//       .catch((error) => {
//         setLoading(false);
//         setMessageType("Danger");
//         setMessage("Something went to wrong.");
//         setShowAlert(true);
//       });
//   }, [id, API_URL, API_VERSION]);

//   function updateData() {
//     if (name && description) {
//       setLoading(true);
//       axios
//         .patch(
//           `${API_URL}/${API_VERSION}/user_doc/${id}`,
//           {
//             name,
//             description,
//             user,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         )
//         .then((response) => {
//           setLoading(false);
//           setMessageType("Success");
//           setMessage(t("File is update successfully."));
//           setShowAlert(true);
//           setTimeout(() => {
//             navigate(`/user-doc`);
//           }, 3000);
//         })
//         .catch((error) => {
//           setLoading(false);
//           setMessageType("Danger");
//           setMessage("Something went to wrong.");
//           setShowAlert(true);
//         });
//     }
//   }
//   return (
//     <>
//       {loading ? (
//         <Loader />
//       ) : (
//         <section className="client_main">
//           <ReactTost
//             onHide={() => {
//               setShowAlert(false);
//               setMessageType("");
//               setMessage("");
//             }}
//             alertClass={messageType}
//             alertMessage={message}
//             alertDo={showAlert}
//           />
//           <Container>
//             <Row className="justify-content-center">
//               <Col xxl={6} xl={6} lg={8} md={10} sm={12}>
//                 <div className="boxwrap">
//                   <h1>{t("Update document")}</h1>
//                   <div className="form-group mb-3">
//                     <label className="form-label">{t("Title")}</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="name"
//                       name="name"
//                       value={name}
//                       onChange={(event) => setName(event.target.value)}
//                     />
//                   </div>

//                   <div className="form-group mb-3">
//                     <label className="form-label">{t("Description")}</label>
//                     <textarea
//                       value={description}
//                       className="form-control"
//                       aria-label="With textarea"
//                       onChange={(event) => setDescription(event.target.value)}
//                     ></textarea>
//                   </div>
//                   <div className="text-start mb-4">
//                     <input
//                       type="button"
//                       value={t("SUBMIT")}
//                       className="userbutton px-4 py-3"
//                       onClick={() => updateData()}
//                     />
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//           </Container>
//         </section>
//       )}
//     </>
//   );
// };

// export default UserUploadDoc;
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserUploadDoc = () => {
  const [loading, setLoading] = useState(false);
  let user = localStorage.getItem("id");
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/user_doc/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setName(response.data.name);
        setDescription(response.data.description);
      })
      .catch((error) => {
        setLoading(false);
        setMessageType("Danger");
        setMessage(t("Something went wrong."));
        setShowAlert(true);
      });
  }, [id, API_URL, API_VERSION]);

  function updateData() {
    // Reset error messages
    setNameError("");
    setDescriptionError("");

    if (name.trim() === "") {
      setNameError(t("Title is required"));
    }

    if (description.trim() === "") {
      setDescriptionError(t("Description is required"));
    }

    if (name && description) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}/${API_VERSION}/user_doc/${id}`,
          {
            name,
            description,
            user,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setMessageType("Success");
          setMessage(t("File is updated successfully."));
          setShowAlert(true);
          setTimeout(() => {
            navigate(`/user-doc`);
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          setMessageType("Danger");
          setMessage(t("Something went wrong."));
          setShowAlert(true);
        });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="client_main">
          <ReactTost
            onHide={() => {
              setShowAlert(false);
              setMessageType("");
              setMessage("");
            }}
            alertClass={messageType}
            alertMessage={message}
            alertDo={showAlert}
          />
          <Container>
            <Row className="justify-content-center">
              <Col xxl={6} xl={6} lg={8} md={10} sm={12}>
                <div className="boxwrap">
                  <h1>{t("Update document")}</h1>
                  <div className="form-group mb-3">
                    <label className="form-label">{t("Title")}</label>
                    <input
                      type="text"
                      className={`form-control ${nameError ? 'is-invalid' : ''}`}
                      id="name"
                      name="name"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                        setNameError(""); // Clear the error when the user starts typing
                      }}
                    />
                    {nameError && (
                      <div className="invalid-feedback">
                        {nameError}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-3">
                    <label className="form-label">{t("Description")}</label>
                    <textarea
                      value={description}
                      className={`form-control ${descriptionError ? 'is-invalid' : ''}`}
                      aria-label="With textarea"
                      onChange={(event) => {
                        setDescription(event.target.value);
                        setDescriptionError(""); // Clear the error when the user starts typing
                      }}
                    ></textarea>
                    {descriptionError && (
                      <div className="invalid-feedback">
                        {descriptionError}
                      </div>
                    )}
                  </div>
                  <div className="text-start mb-4">
                    <input
                      type="button"
                      value={t("SUBMIT")}
                      className="userbutton px-4 py-3"
                      onClick={() => updateData()}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default UserUploadDoc;
