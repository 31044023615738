import axios from "axios";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserDocDelete = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const{t}=useTranslation();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const[loading,setLoading]=useState(false)

  function deleteDoc(id) {
   
    if (id) {
      setLoading(true)
      axios
        .delete(`${API_URL}/${API_VERSION}/user_doc/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoading(false)
          setType("Success");
          setMessage(t("You Doc is deleted."));
          setShowAlert(true);
          props.onHide();
        })
        .catch((error) => {
          setType("Danger");
          setMessage(t("Something went to wrong."));
          setShowAlert(true);
        });
    }
  }
  return (
    <>
    
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? <Loader/> :
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Delete Document")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("Are you sure you want to delete your Document?")}
          </p>
        </Modal.Body>
        <Modal.Footer>
         
          <Button
            className="button bg-danger"
            onClick={() => deleteDoc(props.id)}
          >
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
}
    </>
  );
};

export default UserDocDelete;
