import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";


// bootstarp
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './App.css';

// common routes
import VerifyEmail from "./Component/VerifyEmail";

// Admin routs
import Login from "./Component/Admin/Login";
import Dashboard from "./Component/Admin/Dashboard";
import RateLimit from "./Component/Admin/RateLimit";
import Clients from "./Component/Admin/Client/Clients";
import AddClient from "./Component/Admin/Client/AddClient";
import EditClient from "./Component/Admin/Client/EditClient";
import UploadDoc from "./Component/Admin/Client/UploadDoc";
import EditDoc from "./Component/Admin/Client/EditDoc";
import DocList from "./Component/Admin/Client/DocList";
import Bots from "./Component/Admin/Bot/Bots";
import AddBot from "./Component/Admin/Bot/AddBot";
import ChatBot from "./Component/Admin/Bot/ChatBot";
import Header from "./Component/Admin/Layout/Header";
import Sidebar from "./Component/Admin/Layout/Sidebar"
import PlansList from "./Component/Admin/Plan/PlansList";
import AddPlan from "./Component/Admin/Plan/AddPlan";
import PaymentHistory from "./Component/Admin/PaymentHistory";
import SubscriptionFailed from "./Component/Admin/SubscriptionFailed";
import EmailTemplate from "./Component/Admin/EmailTemplate/EmailTemplate";
// user routs
import HeaderUser from "./Component/User/Layout/Header";
import UserLogin from "./Component/User/Login";
import Success from "./Component/User/Success";
import Canceled from "./Component/User/Canceled";
import UserRegister from "./Component/User/UserRegister";
import UserForgotPassword from "./Component/User/UserForgotPassword";
import UserResetPassword from "./Component/User/UserResetPassword";
import UserDoc from "./Component/User/Doc/UserDoc";
import UserDocEdit from "./Component/User/Doc/UserDocEdit";
import UserBots from "./Component/User/Bot/UserBots";
import UserChatBot from "./Component/User/Bot/UserChatBot";
import UserUploadDoc from "./Component/User/Doc/UserUploadDoc";
import Plans from "./Component/User/Plan/Plans";
import MyProfile from "./Component/User/MyProfile";
import ChatHistory from "./Component/User/ChatHistory/ChatHistory";
import FreeChatbot from "./Component/FreeChatbot";
import Iframe from "./Component/Iframe";
import ClientChatbot from "./Component/ClientChatbot";

import BasePrompt from "./Component/Admin/PlanInfo/BasePrompt";
import EmailVerify from "./Component/Admin/EmailTemplate/EmailVerify";
import ResetPassword from "./Component/Admin/EmailTemplate/ResetPasswordLink";
import UserResendVerifyEmail from "./Component/User/UserResendVerifyEmail";
import ResendVerifyEmail from "./Component/User/ResendEmailVerification";
import ChatLimitReached from "./Component/Admin/EmailTemplate/ChatLimitReached";


function App() {

  const [isSidebarActive, setSidebarActive] = useState(false);
  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };
  const isLoggedIn = useSelector((state) => state.user.userData);
  const isAdmin = useSelector((state) => state.user.IsUserLevel);

  if (isAdmin === 'admin') {
    return (
      <div className={`dashboard-main ${isSidebarActive ? 'dashboard-compact' : ''}`}>
        <Router>
          {isLoggedIn && <Sidebar onToggleSidebar={toggleSidebar} />}
          {isLoggedIn && <Header onToggleSidebar={toggleSidebar} />}
          <div className='dashboard-content'>
            <Routes>
              <Route
                path="/admin"
                element={(isAdmin === 'admin') ? <Dashboard /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/dashboard"
                element={(isAdmin === 'admin') ? <Dashboard /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/clients"
                element={(isAdmin === 'admin') ? <Clients /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/add-client"
                element={(isAdmin === 'admin') ? <AddClient /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/email-template"
                element={(isAdmin === 'admin') ? <EmailTemplate /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/email-verify"
                element={(isAdmin === 'admin') ? <EmailVerify /> : <Navigate to="/admin" />}
              />
                <Route
                path="/admin/chat-limit"
                element={(isAdmin === 'admin') ? <ChatLimitReached /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/resetPassword"
                element={(isAdmin === 'admin') ? <ResetPassword /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/rate-limit"
                element={(isAdmin === 'admin') ? <RateLimit /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/edit-client/:id"
                element={(isAdmin === 'admin') ? <EditClient /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/doc-list/:id"
                element={(isAdmin === 'admin') ? <DocList /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/upload-doc/:id"
                element={(isAdmin === 'admin') ? <UploadDoc /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/edit-client-doc/:id"
                element={(isAdmin === 'admin') ? <EditDoc /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/bots/:id"
                element={(isAdmin === 'admin') ? <Bots /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/add-bot"
                element={(isAdmin === 'admin') ? <AddBot /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/chatbot/:id"
                element={(isAdmin === 'admin') ? <ChatBot /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/plans-list"
                element={(isAdmin === 'admin') ? <PlansList /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/add-plan/:id?"
                element={(isAdmin === 'admin') ? <AddPlan /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/planInfo"
                element={(isAdmin === 'admin') ? <BasePrompt /> : <Navigate to="/admin" />}
              />

              <Route
                path="/admin/payment-history"
                element={(isAdmin === 'admin') ? <PaymentHistory /> : <Navigate to="/admin" />}
              />
              <Route
                path="/admin/subscription-failed"
                element={(isAdmin === 'admin') ? <SubscriptionFailed /> : <Navigate to="/admin" />}
              />
            </Routes>
          </div>
        </Router>
      </div>
    );
  } else if (isAdmin === 'user') {
    return (
      <>
        <Router>
          <HeaderUser />
          <Routes>
            <Route path="/user-bots" element={isLoggedIn ? <UserBots /> : <Navigate to="/" />} />
            <Route path="/user-doc" element={isLoggedIn ? <UserDoc /> : <Navigate to="/" />} />
            <Route path="/user-doc-edit/:id" element={isLoggedIn ? <UserDocEdit /> : <Navigate to="/" />} />
            <Route path="/user-chatbot/:id" element={isLoggedIn ? <UserChatBot /> : <Navigate to="/" />} />
            <Route path="/user-upload-doc" element={isLoggedIn ? <UserUploadDoc /> : <Navigate to="/" />} />
            <Route path="/plans" element={isLoggedIn ? <Plans /> : <Navigate to="/" />} />
            <Route path="/my-profile" element={isLoggedIn ? <MyProfile /> : <Navigate to="/" />} />
            <Route path="/chat-history" element={isLoggedIn ? <ChatHistory /> : <Navigate to="/" />} />
            <Route
              path="/public-chatbot/:id"
              element={<FreeChatbot />}
            />
            <Route
              path="/iframe-chatbot/:id"
              element={<Iframe />}
            />
            <Route path="/success/:id" element={<Success />} />
            <Route path="/canceled" element={<Canceled />} />
          </Routes>
        </Router>

      </>
    );
  } else {
    return (
      <>
        <Router>
          <Routes>

            <Route path="/admin" element={<Login />} />

            <Route path="/" element={<UserLogin />} />
            <Route path="/register" element={<UserRegister />} />
            <Route path="/forgot-password" element={<UserForgotPassword />} />
            <Route path="/resend-verifyEmail" element={<UserResendVerifyEmail />} />

            <Route path="/reset-password/:id" element={<UserResetPassword />} />
            <Route
              path="/verify-email/:id"
              element={<VerifyEmail />}
            />
            <Route
              path="/resend-verify-email/:id"
              element={<ResendVerifyEmail />}
            />

            <Route
              path="/public-chatbot/:id"
              element={<FreeChatbot />}
            />
            <Route
              path="/iframe-chatbot/:id"
              element={<Iframe />}
            />
            <Route
              path="/client-chatbot/:id"
              element={<ClientChatbot />}
            />
            <Route path="*" element={<UserLogin />} />
          </Routes>
        </Router>
      </>

    );
  }
}

export default App