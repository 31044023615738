import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";

const AddPlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [planTitle, setPlanTitle] = useState("Add plan");
  const [name, setName] = useState();
  const [price, setPrice] = useState("0");
  const [priceDisable, setPriceDisable] = useState(false);
  const [duration, setDuration] = useState();
  const [maxBotCount, setMaxBotCount] = useState("0");
  const [maxFileCount, setMaxFileCount] = useState("0");
  const [maxFileSize, setMaxFileSize] = useState("0");
  const [maxUrlCount, setMaxUrlCount] = useState("0");
  const [maxQuestionCount, setMaxQuestionCount] = useState("0");
  const [isCustom, setIsCustom] = useState(false);
  const [position, setPosition] = useState(0);
  const [chatgpt, setChatGpt4] = useState(false);
 
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [english, setEnglish] = useState();
  const [german, setGerman] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    duration: "",
    maxBotCount: "",
    maxFileCount: "",
    maxFileSize: "",
    maxQuestionCount: "",
    english: "",
    german: "",
  });
  // console.log("fieldErrors before setting:", fieldErrors);

  function savePlan(e) {
    e.preventDefault();
    const errors = {};
    if (!name) {
      errors.name = "Name is required.";
    }
    if (!duration) {
      errors.duration = "duration is required.";
    }
    if (!maxBotCount) {
      errors.maxBotCount = "Bot limit is required.";
    }
    if (!maxFileCount) {
      errors.maxFileCount = "file limit is required.";
    }
    if (!maxQuestionCount) {
      errors.maxQuestionCount = "question Limit is required.";
    }
    if (!english) {
      errors.english = "english is required.";
    }
    if (!german) {
      errors.german = "german is required.";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      // console.log("Errors:", errors);
      return;
    }
    if (
      name &&
      duration &&
      maxBotCount &&
      maxFileCount &&
      maxFileSize &&
      maxQuestionCount &&
      english &&
      german
    ) {
      if (id) {
        setLoader(true);

        const errors = {};

        axios
          .patch(
            `${API_URL}/${API_VERSION}/plan/${id}`,
            {
              name,
              // price,
              isCustom,
              duration,
              maxBotCount,
              maxFileCount,
              maxFileSize,
              maxUrlCount,
              maxQuestionCount,
              position,
              planInfoEnglish: english,
              planInfoGerman: german,
              chatgpt4: chatgpt,
             
            },
            {
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setLoader(false);
            setType("Success");
            setMessage("Plan is updated.");
            setShowAlert(true);
            setTimeout(() => {
              navigate("/admin/plans-list");
            }, 3000);
          })
          .catch((error) => {
            setLoader(false);
            setType("Danger");
            setMessage("Something went to wrong.");
            setShowAlert(true);
          });
      } else {
        setLoader(true);

        const errors = {};

        if (!name) {
          errors.name = "Name is required.";
        }
        if (!duration) {
          errors.duration = "duration is required.";
        }
        if (!maxBotCount) {
          errors.maxBotCount = "Bot limit is required.";
        }
        if (!maxFileCount) {
          errors.maxFileCount = "file limit is required.";
        }
        if (!maxQuestionCount) {
          errors.maxQuestionCount = "question Limit is required.";
        }
        if (!english) {
          errors.english = "english is required.";
        }
        if (!german) {
          errors.german = "german is required.";
        }

        if (Object.keys(errors).length > 0) {
          setFieldErrors(errors);
          // console.log(errors)
          return;
        }
        axios
          .post(
            `${API_URL}/${API_VERSION}/plan`,
            {
              name,
              price,
              duration,
              maxBotCount,
              maxFileCount,
              maxFileSize,
              maxUrlCount,
              maxQuestionCount,
              position,
              chatgpt4: chatgpt,
              
              planInfoEnglish: english,
              planInfoGerman: german,
              isCustom,
            },
            {
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setType("Success");
            setMessage("Plan is added.");
            setShowAlert(true);
            setTimeout(() => {
              navigate("/admin/plans-list");
            }, 3000);
          })
          .catch((error) => {
            setLoader(false);
            setType("Danger");
            setMessage(
              error.response.data.message
                ? error.response.data.message
                : "Something went to wrong."
            );
            setShowAlert(true);
          });
      }
    }
  }
  function handleInputChange(field) {
    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  }

  useEffect(() => {
    if (id) {
      setLoader(true);
      axios
        .get(`${API_URL}/${API_VERSION}/plan/${id}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoader(false);
       
          setName(response.data.name);
          setPrice(response.data.price);
          setPriceDisable(true);
          setDuration(response.data.duration);
          setMaxBotCount(response.data.maxBotCount);
          setMaxFileCount(response.data.maxFileCount);
          setMaxFileSize(response.data.maxFileSize);
          setMaxUrlCount(response.data.maxUrlCount);
          setMaxQuestionCount(response.data.maxQuestionCount);
          setIsCustom(response.data.isCustom);
          setPosition(response.data.position);
          setPlanTitle("Save");
        
          setChatGpt4(response.data.chatgpt4);
          setEnglish(response.data.planInfoEnglish);
          setGerman(response.data.planInfoGerman);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  }, [id, API_URL, API_VERSION]);
  return (
    <>
      <section className="client_main">
        <ReactTost
          onHide={() => {
            setShowAlert(false);
            setType("");
            setMessage("");
          }}
          alertClass={type}
          alertMessage={message}
          alertDo={showAlert}
        />

        <Container>
          <Row className="justify-content-center">
            <Col xxl={6} xl={6} lg={8} md={10} sm={12}>
              <div className="boxwrap">
                <h1>{planTitle}</h1>
                <Row>
                  {/* <Col md={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Title</label>
                      <input
                        type="text"
                        className={`form-control ${fieldErrors.name ? 'is-invalid' : ''}`}

                        id="name"
                        name="name"
                        placeholder="Enter title here"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                      {fieldErrors.name && (
                        <div className="invalid-feedback">{fieldErrors.name}</div>
                      )}
                    </div>
                  </Col> */}
                  <Col md={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Title</label>
                      <input
                        type="text"
                        className={`form-control ${
                          fieldErrors.name ? "is-invalid" : ""
                        }`}
                        id="name"
                        name="name"
                        placeholder="Enter title here"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                          handleInputChange("name");
                        }}
                      />
                      {fieldErrors.name && (
                        <div className="invalid-feedback">
                          {fieldErrors.name}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="form-group mb-3">
                      <Form.Check
                        type="checkbox"
                        label="is Custom"
                        checked={isCustom}
                        onChange={(e) => setIsCustom(e.target.checked)}
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="form-group mb-3">
                      <Form.Check
                        type="checkbox"
                        label="include chatgpt 4"
                        checked={chatgpt}
                        onChange={(e) => setChatGpt4(e.target.checked)}
                      />
                    </div>
                  </Col>
                
                  
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Position</label>
                      <input
                        type="number"
                        className="form-control"
                        id="position"
                        name="position"
                        placeholder=""
                        value={position}
                        onChange={(event) => setPosition(event.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Plan Info In English</label>
                      <input
                        type="text"
                        className={`form-control ${
                          fieldErrors.english ? "is-invalid" : ""
                        }`}
                        id="english"
                        name="englsih"
                        value={english}
                        onChange={(event) => {
                          setEnglish(event.target.value);
                          handleInputChange("english");
                        }}
                      />
                      {fieldErrors.english && (
                        <div className="invalid-feedback">
                          {fieldErrors.english}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Plan Info In German</label>
                      <input
                        type="text"
                        className={`form-control ${
                          fieldErrors.german ? "is-invalid" : ""
                        }`}
                        id="german"
                        name="german"
                        value={german}
                        onChange={(event) => setGerman(event.target.value)}
                      />
                      {fieldErrors.german && (
                        <div className="invalid-feedback">
                          {fieldErrors.german}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Price</label>
                      <input
                        disabled={priceDisable}
                        type="number"
                        className="form-control"
                        id="price"
                        name="price"
                        placeholder="Enter price here"
                        value={price}
                        onChange={(event) => setPrice(event.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Frequency</label>
                      <select
                        className={`form-control ${
                          fieldErrors.duration ? "is-invalid" : ""
                        }`}
                        onChange={(event) => {
                          setDuration(event.target.value);
                          handleInputChange("duration");
                        }}
                        value={duration}
                      >
                        <option selected>Choose frequency</option>
                        <option value="month">Monthly</option>
                        <option value="year">Yearly</option>
                      </select>
                      {fieldErrors.duration && (
                        <div className="invalid-feedback">
                          {fieldErrors.duration}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Bot limit</label>
                      <input
                        type="number"
                        className={`form-control ${
                          fieldErrors.maxBotCount ? "is-invalid" : ""
                        }`}
                        id="botLimit"
                        name="botLimit"
                        placeholder="Bot limit"
                        value={maxBotCount}
                        onChange={(event) => {
                          setMaxBotCount(event.target.value);
                          handleInputChange("maxBotCount");
                        }}
                      />
                      {fieldErrors.maxBotCount && (
                        <div className="invalid-feedback">
                          {fieldErrors.maxBotCount}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">File limit</label>
                      <input
                        type="number"
                        className={`form-control ${
                          fieldErrors.maxFileCount ? "is-invalid" : ""
                        }`}
                        id="fileLimit"
                        name="fileLimit"
                        placeholder="File limit"
                        value={maxFileCount}
                        onChange={(event) => {
                          handleInputChange("maxFileCount");
                          setMaxFileCount(event.target.value);
                        }}
                      />
                      {fieldErrors.maxBotCount && (
                        <div className="invalid-feedback">
                          {fieldErrors.maxFileCount}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Question limit</label>
                      <input
                        type="number"
                        className={`form-control ${
                          fieldErrors.maxQuestionCount ? "is-invalid" : ""
                        }`}
                        id="QuestionLimit"
                        name="QuestionLimit"
                        placeholder="Question limit"
                        value={maxQuestionCount}
                        onChange={(event) => {
                          handleInputChange("maxQuestionCount");
                          setMaxQuestionCount(event.target.value);
                        }}
                      />
                      {fieldErrors.maxQuestionCount && (
                        <div className="invalid-feedback">
                          {fieldErrors.maxQuestionCount}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">Scan URL limit</label>
                      <input
                        type="number"
                        className="form-control"
                        id="urlCount"
                        name="urlCount"
                        placeholder="Scan URL limit"
                        value={maxUrlCount}
                        onChange={(event) => setMaxUrlCount(event.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group mb-3">
                      <label htmlFor="">File size limit</label>
                      <input
                        type="number"
                        className={`form-control ${
                          fieldErrors.maxFileSize ? "is-invalid" : ""
                        }`}
                        id="fileSize"
                        name="fileSize"
                        placeholder="File size limit"
                        value={maxFileSize}
                        onChange={(event) => setMaxFileSize(event.target.value)}
                      />
                      {fieldErrors.maxFileSize && (
                        <div className="invalid-feedback">
                          {fieldErrors.maxFileSize}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {loader ? (
                  <Loader />
                ) : (
                  <div className="text-start mb-4">
                    <input
                      type="button"
                      value={planTitle}
                      className="button px-4 py-3"
                      onClick={(e) => savePlan(e)}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default AddPlan;
