import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../Loader";

const DeleteClientDoc = (props) => {
  const[loader,setLoader]=useState(false);
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete your Client document ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          {/* <button className="button" onClick={props.onHide}>
            Cancel
          </button> */}
          {loader ? <Loader/>:
          <Button variant="danger" onClick={props.onDelete}>
            Delete
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteClientDoc;
