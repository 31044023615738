import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../Loader";

const fileTypes = ["pdf", "doc", "docx", "txt"];

const AddBot = () => {
  const [file, setFile] = useState([]);
  const[loading,setLoading]=useState(false);
  const handleChange = (file) => {
    setFile(file);
  };

  const removeAll = () => {
    setFile([]);
  };

  const removeFile = (fileq) => () => {
    const newFiles = [...allFile];
    newFiles.splice(newFiles.indexOf(fileq), 1);
    setFile(newFiles);
  };

  var allFile = [];
  if (file) {
    for (var i = 0; i < file.length; i++) {
     
      allFile.push(file[i]);
    }
  }

  var files = allFile.map((filess) => (
    <li key={filess.name}>
      {filess.name}
      <div onClick={removeFile(filess)}>
        <i className="fa-solid fa-trash-can"></i>
      </div>
    </li>
  ));

  

  return (
    <>
    {loading ? <Loader/>:
      <section className="datasources_main">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="boxwrap">
                <div className="head_title">
                  <h1>Data Sources</h1>
                </div>
                <div className="fileload">
                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />
                
                  <div className="file_details">
                    <h5>Attached Files</h5>
                    <ul>{files}</ul>
                  </div>
                  {files !== undefined && files.length > 0 && (
                    <button className="button" onClick={removeAll}>
                      Remove All
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={6}>
              <div className="boxwrap text-center">
                <p>Include sources and Create Chatbot Here</p>
                <div className="button w-100">Create Chatbot</div>
              </div>
            </Col>
          </Row>
        </Container>
        <Link className="button" to="/chatbot">
          next step for development
        </Link>
      </section>}
    </>
  );
};

export default AddBot;
