import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";

const UserPlanDelete = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [cancelDetails, setCancelDetails] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const user = localStorage.getItem("id");
  function deleteSubscription(props) {
    axios
      .post(
        `${API_URL}/${API_VERSION}/stripe/updatePayment`,
        {
          user,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        props.onHide();
        setType("Success");
        setMessage(t("Subscription deleted."));
        setShowAlert(true);
      })
      .catch((error) => {
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : t("Something went to wrong.")
        );
        setShowAlert(true);
      });
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Unsubscribe plan")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("Are you sure you want to cancel your subscription?")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="button bg-danger"
              onClick={() => {
                deleteSubscription(props);
              }}
            >
              {t("Delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UserPlanDelete;
