import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";

const UserPlanDelete = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [cancelDetails, setCancelDetails] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function callDelete(props) {
    const { deleteUserId, deletePlanId } = props;
    if (cancelDetails) {
      axios
        .post(
          `${API_URL}/${API_VERSION}/user/cancel-plan-notification/`,
          {
            deleteUserId,
            cancelDetails,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          // console.log(data)
        })
        .catch((e) => {
          // console.log(e);
        });
      setLoading(true);
      axios
        .delete(`${API_URL}/${API_VERSION}/user/active-plan/${deleteUserId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setType(t("Success"));
          setMessage(t("You plan is unsubscribe."));
          setShowAlert(true);
          props.onHide();
          setTimeout(() => {
            navigate(`/plans`);
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          setType("Danger");
          setMessage(t("Something went to wrong."));
          setShowAlert(true);
        });
    }
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("Unsubscribe plan")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("Are you sure you want to unsubscribe your plan?")}</p>
            <div className="form-group">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <label className="form-label mb-0">
                  {t("Why you want to cancel your subscription?")}
                </label>
              </div>
              <textarea
                rows="3"
                id="cancelDetails"
                className="form-control"
                spellcheck="false"
                value={cancelDetails}
                onChange={(event) => setCancelDetails(event.target.value)}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="button bg-danger"
              onClick={() => {
                callDelete(props);
              }}
            >
              {t("Delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UserPlanDelete;
