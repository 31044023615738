import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import axios from "axios";
import Loader from "../Loader";

const RateLimit = () => {
  const [updateId, setUpdateId] = useState(0);
  const [limit, setLimit] = useState();
  const [seconds, setSeconds] = useState();
  const [errorDisplay, setErrorDisplay] = useState();
  const [anotherError, setAnotherError] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  function SaveData(e) {
    e.preventDefault();
    let maxQuestion = limit;
    let msgLimit = seconds;
    let msgErr = errorDisplay;
    let anotherErr = anotherError;

    if (updateId) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}/${API_VERSION}/rateLimit/${updateId}`,
          {
            maxQuestion,
            msgLimit,
            msgErr,
            anotherErr,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setLoading(true)
      axios
        .post(
          `${API_URL}/${API_VERSION}/rateLimit`,
          {
            maxQuestion,
            msgLimit,
            msgErr,
            anotherErr,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/rateLimit`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.length) {
          setLimit(response.data[0].maxQuestion);
          setSeconds(response.data[0].msgLimit);
          setErrorDisplay(response.data[0].msgErr);
          setAnotherError(response.data[0].anotherErr);
          setUpdateId(response.data[0].id);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [API_URL, API_VERSION]);
  return (
    <>
      <section className="client_main">
        <Container>
          {loading ? <Loader/>:
          <Row className="justify-content-center">
            <Col lg={12} md={10} sm={12}>
              <div className="boxwrap">
                <h1>Rate limit</h1>
                <Row>
                  <Col md={12}>
                    <div className="form-group mb-3">
                      <label
                        className="d-flex flex-wrap align-items-center gap-3"
                        htmlFor=""
                      >
                        Limit to only{" "}
                        <input
                          type="number"
                          className="form-control d-inline"
                          style={{ width: "100px" }}
                          id="limit"
                          name="limit"
                          value={limit}
                          onChange={(event) => setLimit(event.target.value)}
                        />{" "}
                        messages every{" "}
                        <input
                          type="number"
                          className="form-control d-inline"
                          style={{ width: "100px" }}
                          id="seconds"
                          name="seconds"
                          value={seconds}
                          onChange={(event) => setSeconds(event.target.value)}
                        />{" "}
                        seconds.
                      </label>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        Show this message to show when limit is reached
                      </label><br></br>
                      English
                      <input
                        type="text"
                        className="form-control"
                        id="errorDisplay"
                        name="errorDisplay"
                        value={errorDisplay}
                        onChange={(event) =>
                          setErrorDisplay(event.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group mb-3">
                      German
                      <input
                        type="text"
                        className="form-control"
                        id="errorDisplay"
                        name="anotherError"
                        value={anotherError}
                        onChange={(event) =>
                          setAnotherError(event.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {loading ? <Loader/>:
                <div className="text-start mb-4">
                  <button
                    className="button px-4 py-3"
                    onClick={(e) => SaveData(e)}
                  >
                    Save / Update
                  </button>
                </div>}
              </div>
            </Col>
          </Row>}
        </Container>
      </section>
    </>
  );
};

export default RateLimit;
