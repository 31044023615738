// import { Container,Card,Row,Col } from "react-bootstrap";
// import { Link } from "react-router-dom";

// const EmailTemplate = ()=>{
//     return(
//         <>
//         <section className="clients_main">
//         <Container fluid>
//           <Row>
//             <Col md={12}>
//               <Card>
//                 <Card.Header as="h5">
//                   <div className="d-flex align-items-center justify-content-between gap-3">
//                     <div className="head_title">
//                       <h3 className="mb-0">Email Template</h3>
//                     </div>
//                     {/* <Link className="button" to={`/admin/upload-doc/${id}`}>
//                     <i className="fa-solid fa-circle-plus"></i> Add Document
//                   </Link> */}
//                   </div>
//                 </Card.Header>
//                 <Card.Body>
//                   <table className="client_table">
//                     <thead>
//                       <tr className="d-none d-lg-table-row">
//                         <th width="12%">Email Template for Email Verification</th>
//                         <th>Action</th>
//                         <th width="23%">Email Template for Password Reset</th>
                        
//                       </tr>
//                     </thead>
//                     <tbody>
                     
                       
//                             <tr className="tr" >
                            
//                               <td>
//                                 <strong className="ht">Email</strong>
//                                 <p className="d-flex gap-2">
                               
//                                   <Link
                                   
//                                     to={`/admin/email-verify`}
//                                   >
//                                     Add  Email Verfication Template
                               
//                                   </Link>
                              
                                    
//                                 </p>
//                               </td>
//                               <td>
//                                 <strong className="ht">Email</strong>
//                                 <p className="d-flex gap-2">
                               
//                                   <Link
                                   
//                                     to={`/admin/resetPassword`}
//                                   >
//                                     Add  Reset Password Email Template
                               
//                                   </Link>
                              
                                    
//                                 </p>
//                               </td>
//                             </tr>
                       
//                     </tbody>
//                   </table>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//         </>
//     )
// }
// export default EmailTemplate;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../Loader";

const EmailTemplate = () => {
 
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const[loader,setLoader]=useState(false);
  

  return (
    <>
    {loader ? <Loader/>:
      <section className="clients_main">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header as="h5">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div className="head_title">
                      <h3 className="mb-0">Email Template</h3>
                    </div>
                   
                  </div>
                </Card.Header>
                <Card.Body>
                  <table className="client_table">
                    <thead>
                      <tr className="d-none d-lg-table-row">
                        <th width="50%">Email Template</th>
                        
                        <th width="50%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                          <tr className="tr">
                            <td>
                              <strong className="ht">Email: </strong>
                              <p>Email Template for Email Verification</p>
                            </td>
                           
                            <td>
                              <strong className="ht">Action : </strong>
                              <p className="d-flex gap-2">
                                <Link
                                  className="button"
                                  to={`/admin/email-verify`}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </Link>
                               
                              </p>
                            </td>
                          </tr>
                          <tr className="tr">
                            <td>
                              <strong className="ht">Email: </strong>
                              <p>Email Template for Password Reset</p>
                            </td>
                           
                            <td>
                              <strong className="ht">Action : </strong>
                              <p className="d-flex gap-2">
                                <Link
                                  className="button"
                                  to={`/admin/resetPassword`}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </Link>
                               
                              </p>
                            </td>

                          </tr>
                          <tr className="tr">
                            <td>
                              <strong className="ht">Email: </strong>
                              <p>Email Template when chat limit reached</p> 
                            </td>
                           
                            <td>
                              <strong className="ht">Action : </strong>
                              <p className="d-flex gap-2">
                                <Link
                                  className="button"
                                  to={`/admin/chat-limit`}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </Link>
                               
                              </p>
                            </td>
                          </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>}
    </>
  );
};

export default EmailTemplate;
