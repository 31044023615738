
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserFormSide from "./UserFormSide";
import UserLanguage from "./UserLanguage";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/adminUser";
import ReactTost from "../ReactTost";
import Loader from "../Loader";

const Login = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    email: "",
    password: "",
  });

  function checkUser(e) {
    e.preventDefault();
    const errors = {};

    if (!email) {
      errors.email = t("Email is required.");
    }
    if (!password) {
      errors.password = t("Password is required.");
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    setLoading(true);
    axios
      .post(`${API_URL}/${API_VERSION}/auth/login`, {
        email,
        password,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.user.is_active === false) {
          setType("Danger");
          setMessage(t("You are currently not active."));
          setShowAlert(true);
          navigate("/");
        } else if (response.data.user.isEmailVerified === false) {
          setType("Danger");
          setMessage(t("Please verify your email and try again"));
          setShowAlert(true);
          navigate("/");
        } else if (
          response.data.tokens.access.token &&
          response.data.user.role === "user"
        ) {
          localStorage.setItem("isLogin", true);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("isRole", response.data.user.role);
          localStorage.setItem("EMAIL", response.data.user.email);
          localStorage.setItem("NAME", response.data.user.name);
          localStorage.setItem("token", response.data.tokens.access.token);
          dispatch(
            login({
              userData: true,
              IsUserLevel: response.data.user.role,
            })
          );
          navigate("user-bots");
        } else {
          setType("Danger");
          setMessage(t("You are not allowed."));
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(t("Login details are wrong."));
        setShowAlert(true);
      });
  }

  return (
    <>
      <UserLanguage claval="" />
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="userform_wrap">
          <Container fluid>
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <UserFormSide pageTitle={t("Welcome back!")} />
              </Col>
              <Col lg={6}>
                <section className="user_form">
                  <Container>
                    <Row className="justify-content-center">
                      <Col xl={8} lg={10} md={10}>
                        <div className="boxwrap p-md-5 p-4">
                          <h2>{t("Login")}</h2>
                          <p className="mb-3">
                            {t("Sign in to your account to continue")}
                          </p>
                          <form action="">
                            <Row>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Email address")} <span>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  {fieldErrors.email && (
                                    <div className="error" style={{color:'red'}}>{fieldErrors.email}</div>
                                  )}
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label for="" className="form-label">
                                      {t("Password")} <span>*</span>
                                    </label>
                                    <Link to="/forgot-password">
                                      {t("Lost password ?")}
                                    </Link>
                                  </div>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                  {fieldErrors.password && (
                                    <div className="error" style={{color:'red'}}>{fieldErrors.password}</div>
                                  )}
                                </div>
                              </Col>
                              <div className="text-start mb-4">
                                <input
                                  type="button"
                                  value={t("Sign in")}
                                  className="button px-md-4 py-md-3"
                                  onClick={(e) => checkUser(e)}
                                />
                              </div>
                              <Col md={12} className="mt-3">
                                {t("Not Registered ?")}{" "}
                                <Link to="/register">
                                  {t("Create account ?")}
                                </Link>
                              </Col>
                              <Col md={12} className="mt-3">
                                <Link to="/resend-verifyEmail">
                                  {t("resend verify email link ?")}
                                </Link>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Login;
