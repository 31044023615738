import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import axios from "axios";
import Loader from "../../Loader";

const BasePrompt = () => {
  const [updateId, setUpdateId] = useState(0);
  const [english, setEnglish] = useState();
  const [german, setGerman] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    english: "",
    german: "",
  });
  function SaveData(e) {
    e.preventDefault();

    const errors = {};
    if (!english) errors.english = "Prompt in English is required.";
    if (!german) errors.german = "Prompt in German is required.";

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    if (updateId) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}/${API_VERSION}/planInfo/${updateId}`,
          {
            basePromptEnglish: english,
            basePromptGerman: german,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error);
        });
    } else {
      setLoading(true);
      axios
        .post(
          `${API_URL}/${API_VERSION}/planInfo`,
          {
            basePromptEnglish: english,
            basePromptGerman: german,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error);
        });
    }
  }
  function handleInputChange(field) {
    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/planInfo`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.length) {
          setEnglish(response.data[0].basePromptEnglish);
          setGerman(response.data[0].basePromptGerman);
          setUpdateId(response.data[0].id);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  }, [API_URL, API_VERSION]);
  return (
    <>
      <section className="client_main">
        <Container>
          {loading ? (
            <Loader />
          ) : (
            <Row className="justify-content-center">
              <Col lg={12} md={10} sm={12}>
                <div className="boxwrap">
                  <h1>Default prompt settings for users</h1>
                  <Row>
                    <Col md={12}>
                      <div className="form-group mb-3">
                        Prompt in english
                        <textarea
                          rows="4"
                          id="exampleForm.ControlTextarea1"
                          className={`form-control ${
                            fieldErrors.english ? "is-invalid" : ""
                          }`}
                          spellcheck="false"
                          value={english}
                          onChange={(event) => {
                            setEnglish(event.target.value);
                            handleInputChange("english");
                          }}
                        ></textarea>
                        {fieldErrors.english && (
                          <div className="invalid-feedback">
                            {fieldErrors.english}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="form-group mb-3">
                        Prompt in german
                        <textarea
                          rows="4"
                          id="exampleForm.ControlTextarea1"
                          className={`form-control ${
                            fieldErrors.german ? "is-invalid" : ""
                          }`}
                          spellcheck="false"
                          value={german}
                          onChange={(event) => {
                            setGerman(event.target.value);
                            handleInputChange("german");
                          }}
                        ></textarea>
                        {fieldErrors.german && (
                          <div className="invalid-feedback">
                            {fieldErrors.german}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="text-start mb-4">
                      <button
                        className="button px-4 py-3"
                        onClick={(e) => SaveData(e)}
                      >
                        Save / Update
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default BasePrompt;
