import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import axios from "axios";
import Loader from "../../Loader";

const ResetPassword = () => {
  const [updateId, setUpdateId] = useState(0);
  const [subjectEnglish, setSubjectEnglish] = useState();
  const [subjectGerman, setSubjectGerman] = useState();
  const [bodyEnglish, setBodyEnglish] = useState();
  const [bodyGerman, setBodyGerman] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  const [isFor, setIsFor] = useState();
  const [fieldErrors, setFieldErrors] = useState({
    subjectEnglish: "",
    subjectGerman: "",
    bodyEnglish: "",
    bodyGerman: "",
  });
  function SaveData(e) {
    e.preventDefault();
    const errors = {};
    if (!subjectEnglish)
      errors.subjectEnglish = "Subject in English is required.";
    if (!subjectGerman) errors.subjectGerman = "Subject in German is required.";
    if (!bodyEnglish) errors.bodyEnglish = "Body in English is required.";
    if (!bodyGerman) errors.bodyGerman = "Body in German is required.";

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    if (updateId) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}/${API_VERSION}/emailTemplate/${updateId}`,
          {
            bodyEnglish,
            bodyGerman,
            subjectEnglish,
            subjectGerman,
            isFor,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error);
        });
    } else {
      setLoading(true);
      axios
        .post(
          `${API_URL}/${API_VERSION}/emailTemplate`,
          {
            bodyEnglish,
            bodyGerman,
            subjectEnglish,
            subjectGerman,
            isFor,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error);
        });
    }
  }
  function handleInputChange(field) {
    // Clear the corresponding error when the user starts typing
    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/emailTemplate`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.length) {
          setBodyEnglish(response.data[1].bodyEnglish);
          setBodyGerman(response.data[1].bodyGerman);
          setSubjectEnglish(response.data[1].subjectEnglish);
          setSubjectGerman(response.data[1].subjectGerman);
          setIsFor(response.data[1].isFor);
          setUpdateId(response.data[1].id);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  }, [API_URL, API_VERSION]);
  return (
    <>
      <section className="client_main">
        <Container>
          {loading ? (
            <Loader />
          ) : (
            <Row className="justify-content-center">
              <Col lg={12} md={10} sm={12}>
                <div className="boxwrap">
                  <h1>Forgot password email content</h1>
                  <Row>
                    {/* <Col md={12}>
                    <div className="form-group mb-3">
                      is For Email Verification or Password Reset?
                      <input
                        type="text"
                        className="form-control"
                        id="errorDisplay"
                        name="anotherError"
                        value={isFor}
                        onChange={(event) =>
                          setIsFor(event.target.value)
                        }
                      />
                    </div>
                  </Col> */}
                    <Col md={12}>
                      <div className="form-group mb-3">
                        Subject in English
                        <input
                          type="text"
                          className={`form-control ${
                            fieldErrors.subjectEnglish ? "is-invalid" : ""
                          }`}
                          id="errorDisplay"
                          name="errorDisplay"
                          value={subjectEnglish}
                          onChange={(event) => {
                            setSubjectEnglish(event.target.value);
                            handleInputChange("subjectEnglish");
                          }}
                        />
                        {fieldErrors.subjectEnglish && (
                          <div className="invalid-feedback">
                            {fieldErrors.subjectEnglish}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group mb-3">
                        Subject in German
                        <input
                          type="text"
                          className={`form-control ${
                            fieldErrors.subjectGerman ? "is-invalid" : ""
                          }`}
                          id="errorDisplay"
                          name="anotherError"
                          value={subjectGerman}
                          onChange={(event) => {
                            setSubjectGerman(event.target.value);
                            handleInputChange("subjectGerman");
                          }}
                        />
                      </div>
                    </Col>
                    <h5>Two variables are available : [($NAME)], [($LINK)]</h5>
                    <Col md={12}>
                      <div className="form-group mb-3">
                        Body in english
                        <textarea
                          rows="6"
                          id="exampleForm.ControlTextarea1"
                          className={`form-control ${
                            fieldErrors.bodyEnglish ? "is-invalid" : ""
                          }`}
                          spellcheck="false"
                          value={bodyEnglish}
                          onChange={(event) => {
                            setBodyEnglish(event.target.value);
                            handleInputChange("bodyEnglish");
                          }}
                        ></textarea>
                      </div>
                    </Col>
                    <h5>Two variables are available : [($NAME)], [($LINK)]</h5>
                    <Col md={12}>
                      <div className="form-group mb-3">
                        Body in German
                        <textarea
                          rows="6"
                          id="exampleForm.ControlTextarea1"
                          className={`form-control ${
                            fieldErrors.bodyGerman ? "is-invalid" : ""
                          }`}
                          spellcheck="false"
                          value={bodyGerman}
                          onChange={(event) => {
                            setBodyGerman(event.target.value);
                            handleInputChange("bodyGerman");
                          }}
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="text-start mb-4">
                      <button
                        className="button px-4 py-3"
                        onClick={(e) => SaveData(e)}
                      >
                        Save / Update
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
