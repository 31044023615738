import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from "../../Loader";
import UserPlanDelete from "../Modal/UserPlanDelete";
import UserPlanDowngradeWarning from "../Modal/UserPlanDowngradeWarning";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

const Plans = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState([]);
  const { t } = useTranslation();
  const user = localStorage.getItem("id");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [downgradeModalShow, setDowngradeModalShow] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [userPlan, setUserPlan] = useState();
  const [allPlan, setAllPlan] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [planId, setPlanId] = useState();
  const [stripId, setStripeId] = useState();
  function getPlan() {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/plan`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setLoading(false);
        setAllPlan(response.data);
        setMonthList(
          response.data.filter(
            (month) =>
              month.duration === "month" &&
              month.status === true &&
              month.isCustom === false
          )
        );
        setYearList(
          response.data.filter(
            (year) =>
              year.duration === "year" &&
              year.status === true &&
              year.isCustom === false
          )
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function getUserActivePlan() {
    axios
      .get(`${API_URL}/${API_VERSION}/user/${user}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setLoading(false);
        if (response.data.plan) {
          console.log(response.data.plan);
          setUserPlan(response.data.plan);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    getPlan();
    getUserActivePlan();
  }, []);

  async function checkPlanStatus(newPlan, newPosition) {
    const activePlan = userPlan.id;
    const activePlanPosition = userPlan.position;
    if (activePlan && newPlan) {
      if (newPosition >= activePlanPosition) {
        return 1;
      } else {
        let currantPlanUsage = await checkingPlan();
        if (
          currantPlanUsage.allCount &&
          currantPlanUsage.bot &&
          currantPlanUsage.document &&
          currantPlanUsage.url
        ) {
          const { totalBots, totalDocs, totalUrls } = currantPlanUsage.allCount;
          const allNewData = allPlan.filter((all) => all.id === newPlan);
          // console.log(allNewData[0].maxBotCount);
          // console.log(allNewData[0].maxFileCount);
          // console.log(allNewData[0].maxUrlCount);
          if (
            allNewData[0].maxBotCount < totalBots ||
            allNewData[0].maxFileCount < totalDocs ||
            allNewData[0].maxUrlCount < totalUrls
          ) {
            return 2;
          } else {
            return 1;
          }
        }
        return 2;
      }
    }
    return 0;
  }

  async function getStripeLink(planId, stripId) {
    axios
      .post(
        `${API_URL}/${API_VERSION}/stripe/`,
        {
          planId,
          stripId,
          user
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data) {
          window.open(response.data, "_blank");
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  }

  async function callSubscription(e, planId, stripId, position) {
    e.preventDefault();
    setPlanId(() => planId);
    setStripeId(() => stripId);
    checkPlanStatus(planId, position)
      .then((data) => {
        switch (data) {
          case 1:
            if (planId) {
              setLoading(true);
              getStripeLink(planId, stripId);
            }
            break;
          case 2:
            // downgrade plan so need to remove old data
            // open popup with details
            setDowngradeModalShow(true);
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        // console.log(e)
      });
  }

  function callSubscriptionCancel(e, planId) {
    e.preventDefault();
    setSelectedPlanId(planId);
    setDeleteModalShow(true);
  }

  async function checkingPlan() {
    return axios
      .get(`${API_URL}/${API_VERSION}/user/plan/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return false;
      });
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="plan">
          <Container>
            <Row>
              <Col md={12}>
                <Tabs>
                  <TabList className="d-flex align-items-center justify-content-center">
                    <Tab>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-calendar"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#05204a"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
                        <path d="M16 3v4" />
                        <path d="M8 3v4" />
                        <path d="M4 11h16" />
                        <path d="M7 13h2v2h-2z" />
                      </svg>
                      {t("Monthly plan")}
                    </Tab>
                    <Tab>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-calendar"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#05204a"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" />
                        <path d="M16 3v4" />
                        <path d="M8 3v4" />
                        <path d="M4 11h16" />
                        <path d="M7 13h2v2h-2z" />
                        <path d="M11 13h2v2h-2z" />
                        <path d="M7 17h2v2h-2z" />
                        <path d="M11 17h2v2h-2z" />
                        <path d="M15 13h2v2h-2z" />
                        <path d="M15 17h2v2h-2z" />
                      </svg>
                      {t("Yearly plan")}
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <Row>
                      {userPlan &&
                        monthList &&
                        monthList.map((month, index) => {
                          let isDisabled = true;
                          if (month.price != 0) {
                            isDisabled = false;
                          }
                          return (
                            <Col lg={3} sm={6} key={index}>
                              <div className="plan_box">
                                {userPlan.id === month.id && (
                                  <>
                                    <div className="ribbon">
                                      <span className="rib">
                                        {t("Current Plan")}
                                      </span>
                                    </div>
                                  </>
                                )}
                                <div className="name">
                                  <strong>{month.name}</strong>
                                </div>
                                <div className="prices">
                                  <h1>$ {month.price}</h1>
                                </div>
                                <div className="list">
                                  {/* { if(localStorage.getItem('i18nextLng')=='en')
                                  {
                                    <p>{month.planInfoEnglish}</p>
                                  }
                                  else{
                                    <p>{month.planInfoGerman</p>
                                  }
                                  
                                  } */}
                                  {localStorage.getItem("i18nextLng") ===
                                  "en" ? (
                                    <p>{month.planInfoEnglish}</p>
                                  ) : (
                                    <p>{month.planInfoGerman}</p>
                                  )}

                                  <ul>
                                    <li>
                                      <span>
                                        {month.maxBotCount} {t("Bots")}
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        {month.maxQuestionCount}{" "}
                                        {t("Questions")}
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        {month.maxUrlCount} {t("Scan URLS")}
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        {month.maxFileCount} {t("File Uploads")}
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        {month.maxFileSize} MB{" "}
                                        {t(" Size per file")}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                {userPlan.isCustom === true ? (
                                  " "
                                ) : (
                                  <div className="action">
                                    {(() => {
                                      if (userPlan.id === month.id) {
                                        return (
                                          <button
                                            disabled={isDisabled}
                                            className="userbutton btn btn-danger"
                                            onClick={(e) =>
                                              callSubscriptionCancel(
                                                e,
                                                month.id,
                                                month.stripId
                                              )
                                            }
                                          >
                                            {t("CANCEL")}
                                          </button>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <button
                                              disabled={isDisabled}
                                              className="userbutton"
                                              onClick={(e) =>
                                                callSubscription(
                                                  e,
                                                  month.id,
                                                  month.stripId,
                                                  month.position
                                                )
                                              }
                                            >
                                              {t("SUBSCRIBE")}
                                            </button>
                                          </>
                                        );
                                      }
                                    })()}
                                  </div>
                                )}
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </TabPanel>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TabPanel>
                      <Row>
                        {userPlan &&
                          yearList &&
                          yearList.map((year, index) => {
                            return (
                              <Col lg={3} sm={6} key={index}>
                                <div className="plan_box">
                                  {userPlan.id === year.id && (
                                    <>
                                      <div className="ribbon">
                                        <span className="rib">
                                          {t("Current Plan")}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                  <div className="name">
                                    <strong>{year.name}</strong>
                                  </div>
                                  <div className="prices">
                                    <h1>$ {year.price}</h1>
                                  </div>
                                  <div className="list">
                                    {localStorage.getItem("i18nextLng") ===
                                    "en" ? (
                                      <p>{year.planInfoEnglish}</p>
                                    ) : (
                                      <p>{year.planInfoGerman}</p>
                                    )}
                                    <ul>
                                      <li>
                                        <span>
                                          {year.maxBotCount} {t("bots")}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          {year.maxQuestionCount}{" "}
                                          {t("Question")}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          {year.maxUrlCount} {t("Scan URL")}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          {year.maxFileCount}{" "}
                                          {t("Files upload")}
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          {year.maxFileSize} MB{" "}
                                          {t(" Size per file")}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  {userPlan.isCustom === true ? (
                                    " "
                                  ) : (
                                    <div className="action">
                                      {(() => {
                                        if (userPlan.id === year.id) {
                                          return (
                                            <button
                                              className="userbutton"
                                              onClick={(e) =>
                                                callSubscriptionCancel(
                                                  e,
                                                  year.id
                                                )
                                              }
                                            >
                                              {t("CANCEL")}
                                            </button>
                                          );
                                        } else {
                                          return (
                                            <button
                                              className="userbutton"
                                              onClick={(e) =>
                                                callSubscription(
                                                  e,
                                                  year.id,
                                                  year.stripId,
                                                  year.position
                                                )
                                              }
                                            >
                                              {t("SUBSCRIBE")}
                                            </button>
                                          );
                                        }
                                      })()}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </TabPanel>
                  )}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      <UserPlanDelete
        show={deleteModalShow}
        deletePlanId={selectedPlanId}
        deleteUserId={user}
        onHide={() => {
          getPlan();
          getUserActivePlan();
          setDeleteModalShow(false);
        }}
      />
      <UserPlanDowngradeWarning
        show={downgradeModalShow}
        planId={planId}
        stripId={stripId}
        user={user}
        callSubscription={callSubscription}
        onHide={() => {
          setDowngradeModalShow(false);
          getStripeLink(planId, stripId);
        }}
      />
    </>
  );
};
export default Plans;
