import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserDocDelete from "../Modal/UserDocDelete";
import ReactTost from "../../ReactTost";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserDoc = () => {
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem("id");
  const { t } = useTranslation();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [docList, setDocList] = useState([]);
  const navigate = useNavigate();
  const [isDoc, setIsDoc] = useState(true);
  const [docId, setDocId] = useState("");

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  function checkingPlan() {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/user/plan/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
       
       
        if (response.data.document === true) {
          navigate("/user-upload-doc");
        } else {
          setType("Danger");
          setMessage(t("you cannot upload document please upgrade your plan."));
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(t("Something went wrong"));
        setShowAlert(true);
      });
  }
  function getAllDoc() {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/user_doc?id=${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setDocList(response.data.results);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(t("Something went to wrong."));
        setShowAlert(true);
      });
  }

  useEffect(() => {
    if (isDoc) {
      setIsDoc(false);
      getAllDoc();
    }
  }, [isDoc]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="userdoc_main">
          <ReactTost
            onHide={() => {
              setShowAlert(false);
              setType("");
              setMessage("");
            }}
            alertClass={type}
            alertMessage={message}
            alertDo={showAlert}
          />
          <Container>
            <Row>
              <Col md={12}>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className="head_title">
                    <h1 className="mb-0">{t("My Document")}</h1>
                  </div>
                  <Link className="userbutton" onClick={() => checkingPlan()}>
                    <i className="fa-solid fa-circle-plus"></i>{" "}
                    {t("Add Document")}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              {docList &&
                docList.map((doc, index) => {
                  let iconClass =
                    doc.type === "application/pdf" ? "fa-file-pdf" : "fa-file";
                  return (
                    <Col lg={6} className="mb-4" key={index}>
                    
                      <Card>
                        <div className="action_box">
                          <Link
                            className="button"
                            to={`/user-doc-edit/${doc.id}`}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </Link>
                          <Button
                            variant="danger"
                            onClick={() => {
                              setDocId(doc.id);
                              setDeleteModalShow(true);
                            }}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </Button>
                        </div>
                        <div className="imgbox">
                          <i className={`fa-solid fa-fw ${iconClass}`}></i>
                        </div>
                       
                        <Card.Body>
                          <div className="infobox">
                            <div className="info">
                              <strong>{t("Title")}</strong>
                              <span>{doc.name}</span>
                            </div>
                            <div className="info">
                              <strong>{t("Description")} </strong>
                              <span className="limit">{doc.description}</span>
                            </div>
                            <div className="info">
                              <strong>{t("File name")} </strong>
                              <span>{doc.fileName}</span>
                            </div>
                            {doc.isDocActive ==='2' && <div style={{color:'red'}}>{t("This file is deactivated by admin")}</div>}
                            {doc.isDocActive ==='0' && <div style={{color:'red'}}>{t("This file is not active")}</div>}
                      
                   
                          </div>

                        </Card.Body>
                      
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Container>
        </section>
      )}
      <UserDocDelete
        show={deleteModalShow}
        id={docId}
        onHide={() => {
          setDeleteModalShow(false);
          setIsDoc(true);
        }}
      />
    </>
  );
};

export default UserDoc;
