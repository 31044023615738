import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import UserChatbotData from "./UserChatbotData";
import ChatHistory from "../ChatHistory/ChatHistory";
import UserChatBotSetting from "./UserChatBotSetting";
import UserChatBotInterface from "./UserChatBotInterface";
import UserChatBotSources from "./UserChatBotSources";
import UserChatBotEmbed from "./UserChatBotEmbed";
import UserChatBotShare from "./UserChatBotShare";
import UserChatBotDelete from "./UserChatBotDelete";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserChatBot = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const user = localStorage.getItem("id");
  const [botAll, setBotAll] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [docList, setDocList] = useState([]);
  useEffect(() => {
    const callGetBot = () => {
      setLoading(true);
      axios
        .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          setLoading(false);
          setBotAll(response.data);
          setIsFirst(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error);
        });
    };
    axios
      .get(`${API_URL}/${API_VERSION}/user_doc?id=${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setLoading(false);
        setDocList(response.data.results);
      })
      .catch((error) => {
        setLoading(false);
      });
    if (isFirst) {
      callGetBot();
    }
  }, [user, id, isFirst, API_URL, API_VERSION]);
  function tabIsChange(tabIndex) {
    setIsFirst(true);
  }
  return (
    <>
      <section className="userchatbotsettings">
        <Container>
          <Row>
            <Col md={12} className="mb-4">
              <div className="head_title">
                <h1>{t("My Chatbot Settings")}</h1>
              </div>
            </Col>
            <Col>
              <Tabs defaultIndex={0} onSelect={(index) => tabIsChange(index)}>
                <Row>
                  <Col lg={3}>
                    <TabList className="nav flex-lg-column nav-pills me-xl-3 md-0">
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-message-2"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 9h8" />
                          <path d="M8 13h6" />
                          <path d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z" />
                        </svg>
                        <span>{t("Chatbot")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-history-toggle"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969" />
                          <path d="M14 3.223a9.003 9.003 0 0 1 0 17.554" />
                          <path d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592" />
                          <path d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305" />
                          <path d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356" />
                          <path d="M12 8v4l3 3" />
                        </svg>
                        <span>{t("Chatbot History")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-tool"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5" />
                        </svg>
                        <span>{t("Settings")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-app-window"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                          <path d="M6 8h.01" />
                          <path d="M9 8h.01" />
                        </svg>
                        <span>{t("Chat Interface")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-clipboard-plus"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                          <path d="M10 14h4" />
                          <path d="M12 12v4" />
                        </svg>
                        <span>{t("Sources")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-message-2-code"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 9h8" />
                          <path d="M8 13h6" />
                          <path d="M12 21l-1 -1l-2 -2h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6" />
                          <path d="M20 21l2 -2l-2 -2" />
                          <path d="M17 17l-2 2l2 2" />
                        </svg>
                        <span>{t("Embed on site")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-share"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M8.7 10.7l6.6 -3.4" />
                          <path d="M8.7 13.3l6.6 3.4" />
                        </svg>
                        <span>{t("Share")}</span>
                      </Tab>
                      <Tab>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-trash"
                          width="40"
                          height="40"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 7l16 0" />
                          <path d="M10 11l0 6" />
                          <path d="M14 11l0 6" />
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                        <span>{t("Delete")}</span>
                      </Tab>
                    </TabList>
                  </Col>
                  <Col lg={9}>
                    <div className="tabpanel_box">
                      <TabPanel className="tab-pane">
                        <UserChatbotData botData={botAll} />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <ChatHistory />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <UserChatBotSetting botData={botAll} />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <UserChatBotInterface botData={botAll} />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <UserChatBotSources
                          botData={botAll}
                          docData={docList}
                        />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <UserChatBotEmbed botData={botAll} />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <UserChatBotShare botData={botAll} />
                      </TabPanel>
                      <TabPanel className="tab-pane">
                        <UserChatBotDelete botData={botAll} />
                      </TabPanel>
                    </div>
                  </Col>
                </Row>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserChatBot;
