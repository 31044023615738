// Loader.js
import React from "react";
import "./msgLoading.css"; // You can create a separate CSS file for styling

const MsgLoading = () => {
  return (
    <div className="loading-screen">
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  );
};

export default MsgLoading;