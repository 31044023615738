import axios from "axios";
import React from "react";
import { useState } from "react";

import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";

const ChatBotDelete = () => {
  const { id } = useParams();
  const[loader,setLoader]=useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const navigate=useNavigate();
 function deleteChatBot(){
  axios.delete(`${API_URL}/${API_VERSION}/bot/${id}`,{
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response)=>{
    navigate(`/admin/bots/${id}`)

  }).catch((err)=>{
    console.log(err)
  })
 }
  return (
    <>
      <section className="embed_box">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="embed_wrap">
                <h2>Delete chatbot</h2>
                <p>
                  Are you sure you want to delete your chatbot?
                </p>
                {loader ? <Loader/> :
                <button className="button" variant="danger" onClick={()=>deleteChatBot()}>Delete</button>
  }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ChatBotDelete;
