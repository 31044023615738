// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes loading {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loading-dots {
    width: 60px;
    display: flex;
    justify-content: space-between;
  }
  
  .loading-dots div {
    width: 16px;
    height: 16px;
    background: #007bff;
    border-radius: 50%;
    animation: loading 1.4s infinite ease-in-out;
  }
  
  .loading-dots div:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .loading-dots div:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .loading-dots div:nth-child(3) {
    animation-delay: 0.6s;
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/msgLoading.css"],"names":[],"mappings":";AACA;IACI;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,4CAA4C;EAC9C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":["\n@keyframes loading {\n    0%, 80%, 100% {\n      transform: scale(0);\n    }\n    40% {\n      transform: scale(1);\n    }\n  }\n  \n  .loading-screen {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.8);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 999;\n  }\n  \n  .loading-dots {\n    width: 60px;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .loading-dots div {\n    width: 16px;\n    height: 16px;\n    background: #007bff;\n    border-radius: 50%;\n    animation: loading 1.4s infinite ease-in-out;\n  }\n  \n  .loading-dots div:nth-child(1) {\n    animation-delay: 0.2s;\n  }\n  \n  .loading-dots div:nth-child(2) {\n    animation-delay: 0.4s;\n  }\n  \n  .loading-dots div:nth-child(3) {\n    animation-delay: 0.6s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
