import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const AddBot = (props) => {
  const [name, setName] = useState();
  const handleSubmit = () => {
    props.createBot(name);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Bot
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Name</label>
            <input
              className="form-control"
              type="text"
              name="botName"
              id="botName"
              placeholder="Enter bot name"
              onChange={(event) => setName(event.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddBot;
