import React, {  useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AddBot = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState();
  const [fieldErrors, setFieldErrors] = useState({
    email: "",
  });
  const handleSubmit = () => {
    let errors={}
    if (!name) {
      errors.name= t("Name is required.");
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    props.createBot(name);
  };
  function handleInputChange(field) {

    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  }
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Create New Bot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">{t("Name")}</label>
            <input
              className="form-control"
              type="text"
              name="botName"
              id="botName"
              // placeholder="Enter bot name"
              onChange={(event) => {setName(event.target.value)
              handleInputChange('name')}}
            />
             {fieldErrors.name && (
                                    <div className="error" style={{color:'red'}}>{fieldErrors.name}</div>
                                  )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="userbutton" onClick={handleSubmit}>
            {t("SUBMIT")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddBot;
