import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/adminUser";

const Sidebar = ({ onToggleSidebar }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to close the sidebar and navigate to the link
  const closeSidebarAndNavigate = (link) => {
    if (isMobile) {
      onToggleSidebar();
    }
    navigate(link); // Navigate to the link
  };

  function Logout() {
    dispatch(logout());
    localStorage.clear();
    navigate("/admin");
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="dashboard-nav">
        <header>
          <a
            href="javascript:void(0);"
            className="menu-toggle"
            id="toggleSidebarBtn"
            onClick={onToggleSidebar}
          >
            <i class="fas fa-times"></i>
          </a>
          <Link className="brand-logo" to="/admin">
            <img src="../../images/ChatDocX_logo.svg" alt="" />
          </Link>
        </header>
        <nav className="dashboard-nav-list">
          <Nav className="dashboard-nav-list">
            <Link
              className="dashboard-nav-item active"
              to="/admin"
              onClick={() => closeSidebarAndNavigate("/admin")}
            >
              <i className="fa-solid fa-house"></i> Dashboard
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/clients"
              onClick={() => closeSidebarAndNavigate("/admin/clients")}
            >
              <i className="fa-solid fa-user-group"></i> Clients
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/plans-list"
              onClick={() => closeSidebarAndNavigate("/admin/plans-list")}
            >
              <i className="fa-solid fa-database"></i> Plans
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/planInfo"
              onClick={() => closeSidebarAndNavigate("/admin/planInfo")}
            >
              <i class="fa-solid fa-terminal"></i> Base prompt
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/rate-limit"
              onClick={() => closeSidebarAndNavigate("/admin/rate-limit")}
            >
              <i class="fa-solid fa-ban"></i> Rate limit
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/email-template"
              onClick={() => closeSidebarAndNavigate("/admin/email-template")}
            >
              <i class="fa-solid fa-envelope"></i> Email template
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/payment-history"
              onClick={() => closeSidebarAndNavigate("/admin/payment-history")}
            >
              <i class="fa-brands fa-cc-stripe"></i> Payment history
            </Link>
            <Link
              className="dashboard-nav-item"
              to="/admin/subscription-failed"
              onClick={() =>
                closeSidebarAndNavigate("/admin/subscription-failed")
              }
            >
              <i class="fa-solid fa-money-check"></i> Failed subscriptions
            </Link>
            <div className="nav-item-divider" />
            <a className="dashboard-nav-item" onClick={() => Logout()}>
              <i className="fas fa-sign-out-alt" />
              Logout
            </a>
          </Nav>
        </nav>
      </div>
    </>
  );
};
export default Sidebar;
