import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../Loader";

function EditDoc() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [userId, setUserId] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const[loader,setLoader]=useState(false)
  useEffect(() => {
    setLoader(true);
    axios
      .get(`${API_URL}/${API_VERSION}/users/doc/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoader(false);
        setName(response.data.name);
        setDescription(response.data.description);
        setUserId(response.data.user);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error uploading file:", error);
      });
  }, [id, API_URL, API_VERSION]);
  function updateFile() {
    setLoader(true);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/users/doc/${id}`,
        {
          name,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setLoader(false);
        navigate(`/admin/doc-list/${userId}`);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error uploading file:", error);
      });
  }
  return (
    <>
      <section className="client_main">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={6} xl={6} lg={6} md={8} sm={10}>
              <div className="boxwrap">
                <h1>Update Document</h1>
                <div className="form-group mb-3">
                  <label htmlFor="">Title</label>                  
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="">Description</label> 
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  ></textarea>
                </div>
                {loader ? <Loader/>:
                <div className="text-start mb-4">
                  <input
                    type="button"
                    value="Update"
                    className="button px-4 py-3"
                    onClick={() => updateFile()}
                  />
                </div>
}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default EditDoc;
