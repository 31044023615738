import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TermsConditions = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="termsmodal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {t('Terms and conditions')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="content_box">
        <p>{t("General Terms and Conditions for ChatDocX (Bienpur GmbH)")}</p>

        <h4 className="pt-4">1. {t("Introduction")}</h4>

        <p>1.1 {t("These General Terms and Conditions (GTC) apply to all services offered by Bienpur GmbH, Zürcherstrasse 64, 5400 Baden, under the brand ChatDocX. Our contact information for support requests is: support@chatdocx.ai. ChatDocX provides services for creating and using intelligent chat assistants that can be deployed on websites, shops, and intranets.")}</p>

       
        <h4 className="pt-4">2. {t("Scope")}</h4>

        <p>2.1 {t("These GTC apply to all users of ChatDocX, including individuals, companies, non-profit organizations, and educational institutions. The use of our service presupposes agreement to these terms.")}</p>

       
        <h4 className="pt-4">3. {t("Terms of Use")}</h4>

        <p> {t("3.1. Users are entitled to use ChatDocX within the various subscription plans listed on our website. These plans define the number of configurable chatbots, the volume and size of uploadable files, the number of URLs that can be scanned, and the number of questions a bot can answer within 24 hours.")}</p>

        <p>{t("3.2 Users may not upload copyrighted documents, sensitive data, or offensive information, or scan such materials as URLs. Users are fully responsible for the content they upload and liable for all consequences arising from the bot's responses.")}</p>

        <h4 className="pt-4">4. {t("Payment Terms")}</h4>

        <p> {t("4.1 ChatDocX offers both monthly and annual subscriptions. Details of the various subscription options and their usage possibilities can be viewed on our website.")}</p>

        <p> {t("4.2. Payment can be made by credit card or, upon agreement with ChatDocX, by invoice. In case of delayed payment, the corresponding chatbot will be set to inactive after two weeks and deleted along with the account after another two weeks.")}</p>
        
        <p> {t("4.3. Subscription cancellation is possible at any time at the end of the respective term. ChatDocX reserves the right to terminate the service immediately if it is not used for its intended purpose, if abuse occurs, or if illegal or offensive content is disseminated through the chat service. In such cases, there is no claim for refund or other demands.")}</p>
        <h4 className="pt-4">{t("Liability and Warranty")}</h4>

        <p> {t("5.1 ChatDocX and Bienpur GmbH make no guarantees for the availability or operational times of the chat assistant. Service outages or interruptions do not entitle users to compensation claims or refunds.")}</p>
        <p> {t("5.2. Any liability associated with the use of the chat assistant is excluded by ChatDocX and Bienpur GmbH. We do not guarantee the functions or performance of the chat assistants.")}</p>

<p> {t("5.3. ChatDocX reserves the right to interrupt the service for maintenance and updates.")}</p> 
        <h4 className="pt-4">6. {t("Privacy and Security")} </h4>

        <p>{t("The use of ChatDocX is subject to our privacy policy, which can be viewed separately on our website. These policies govern the processing and protection of users' personal data.")}</p>

        <h4 className="pt-4">7. {t("Contract Termination")}</h4>

        <p> {t("7.1 Each subscription can be cancelled without notice at the end of its term. Cancellation is done through the user account under the 'Subscriptions' menu.")}</p>

        <p> {t("7.2. Upon cancellation of the subscription, the respective chatbots will be deactivated and all associated documents and URLs deleted. A cancelled and inactive account will be deleted no later than six months after the cancellation.")}</p>
        
        <p> {t("7.3. For payments by invoice, ChatDocX / Bienpur GmbH must be informed via email about the cancellation so that ongoing contracts can be terminated.")}</p>
        <h4 className="pt-4">8. {t("Changes to the GTC")}</h4>

        <p>{t("Changes to these GTC will be communicated to users via email. Continued use of the service after such changes constitutes agreement to the new terms.")}</p>

        <h4 className="pt-4">9. {t("Final Provisions")}y</h4>

        <p> {t("9.1 These GTC are governed by Swiss law. The jurisdiction is Baden, Switzerland.")}</p>
        <p> {t("9.2 In the event of disputes, a dispute resolution or mediation process will be conducted under Swiss law at an appropriate mediation authority.")}</p>
       
        <h4 className="pt-4">{t("Privacy Policy for ChatDocX (Bienpur GmbH)")}</h4>

        <h4 className="pt-4">1. {t("General")}</h4>
        
        <p>{t("These Privacy Policies govern the collection, processing, and use of your personal data by ChatDocX, operated by Bienpur GmbH, Zürcherstrasse 64, 5400 Baden, when you use our service.")}</p>

        <h4 className="pt-4">2. {t("Data Processing")}</h4>
        <p>2.1. <strong>{t("Personal Data")}</strong>{t("When registering for ChatDocX, your first name, last name, company name, website, email address, and password are collected.")}</p>
         <p>2.2 <strong>{t("Usage Data")}</strong>{t("During the use of the chat, we process uploaded documents and scanned URLs, as well as IP addresses and browser data for the chat session. These data are deleted after 30 days.")}</p>
         
        <p>2.3 <strong>{t("Data Transfer to Third Parties")}</strong>{t("For chat functions, we transmit user queries and relevant information from documents and URLs encrypted to ChatGPT from Open AI. Personal data is not transferred to third parties.")}</p>
        <h4 className="pt-4">3. {t("Data Storage and Access")}</h4>
        
        <p>3.1. <strong>{t("Storage Location ")}</strong>{t("The data are stored on servers in Switzerland, Germany, and the USA.")}</p>
       <p> 3.2. <strong>{t("Access Authorization ")}</strong>{t("Access to the data is exclusively granted to employees of Bienpur GmbH and commissioned service providers.")}</p> 
       <p>3.3. <strong>{t("Security Measures ")}</strong>{t("Data transfer is encrypted using SSL. Passwords are stored encrypted, and access to servers is protected by SSH and high encryption.")}</p>
        
        <h4 className="pt-4">4. {t("Rights of the Affected Persons")}</h4>

        <p>4.1.<strong>{t("User Rights ")}</strong>{t(" You have the right to information, correction, deletion, and restriction of the processing of your personal data.")}</p>
        <p>4.2.<strong>{t("Contact ")}</strong>{t("To exercise your rights, please contact our data protection officer Mike Schwarz at support@chatdocx.ai or +41565520530.")}</p>

     
        <h4 className="pt-4">5. {t("Cookies and Tracking")}</h4>
        <p>{t("ChatDocX does not use cookies or tracking technologies. Session IDs are stored in the browser and deleted when the browser is closed.")}</p>

        <h4 className="pt-4">6. {t("Changes to the Privacy Policies ")}</h4>
        <p>{t("Changes to these Privacy Policies will be announced via email and can be viewed on our website. By continuing to use the service, you automatically agree to the updated terms.")}</p>
        </div>    
        </Modal.Body>
        <Modal.Footer>
          <Button className="button" onClick={props.onHide}>{t('Close')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TermsConditions