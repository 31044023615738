import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactTost from "../../ReactTost";
import Loader from "../../Loader";
import { useParams } from "react-router";

const ChatBotInterface = (props) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const[botIndexValue, setBotIndexValue] = useState('');
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();
  const[bubbleChatIconColor, setBubbleColor] = useState("#05204a")
  const[bubbleHeight, setBubbleHeight] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const imageList = [
    "../images/chat-icon-blue.svg",
    "../images/chat-icon-star.svg",
    "../images/chat-icon-single-question.svg",
    "../images/chat-icon-robot.svg",
    "../images/chat-icon-hearts.svg",
    "../images/chat-icon-green.svg",
    "../images/chat-icon-double-white.svg",
    "../images/chat-icon-double-black-white.svg",
  ];
const[bubbleWidth, setBubbleWidth] = useState(0);
  const [color, setColor] = useState();
  const [name, setDisplayName] = useState();
  const [chatBotImage, setChatBotImage] = useState();
  const [initialMsg, setInitialMsg] = useState();
  const [suggestedMsg, setSuggestedMsg] = useState();
  const [btnAlign, setBtnAlign] = useState();
  const [suggestedQuestion, setSuggestedQuestion] = useState([]);
  const [messageType, setMessageType] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [BubbleName, setBubbleText] = useState("");
  const [BubbleSec, setBubbleDisappearSec] = useState("");
  const [topHeader, setTopHeader] = useState("#05204a");
  const [topHeaderFont, setTopHeaderFont] = useState("#05204a");
  const [questionBubble, setQuestionBubble] = useState("#05204a");
  const [questionBubbleFont, setQuestionBubbleFont] = useState("#05204a");
  const [answerBubble, setAnswerBubble] = useState("#05204a");
  const [answerBubbleFont, setAnswerBubbleFont] = useState("#05204a");
  const [sendButton, setSendButton] = useState("#05204a");
  const { id } = useParams();

  function resetHandler() {
    setInitialMsg("");
  }
  function changeBotImage(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setChatBotImage(e.target.result);
        setSelectedImage(null); // Clear selected radio button image
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      const selectedRadioValue = event.target.value;
      setSelectedImage(selectedRadioValue);
      setChatBotImage(null); // Clear uploaded file image
    }
  }

  function updateBot(e) {
    e.preventDefault();
    let chatInterface = {
      BubbleName,
      BubbleSec,
      initialMsg,
      suggestedMsg,
      name,
      color,
      btnAlign,
      messageType,
      topHeader,
      topHeaderFont,
      questionBubble,
      questionBubbleFont,
      answerBubble,
      answerBubbleFont,
      sendButton,
      bubbleColor:bubbleChatIconColor,
      bubbleHeight,
      bubbleWidth,
      chatBotIndexValue: botIndexValue
    };
    if (chatBotImage) {
      chatInterface.file = chatBotImage;
    } else if (selectedImage) {
      chatInterface.selectedImage = `${selectedImage}`;
    }
    setLoading(true);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/bot/${id}`,
        {
          chatInterface,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setType("Success");
        setMessage("Setting saved");
        showAlert(true);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        const initial = response.data.chatInterface.initialMsg;
        setBubbleText(response.data.chatInterface.BubbleName);
        setBubbleDisappearSec(response.data.chatInterface.BubbleSec);
        setInitialMsg(initial);
        setBubbleHeight(response.data.chatInterface.bubbleHeight);
        setBubbleWidth(response.data.chatInterface.bubbleWidth);
        setBubbleColor(response.data.chatInterface.bubbleColor)
        const suggestedMsg = response.data.chatInterface.suggestedMsg;
        setSuggestedMsg(suggestedMsg);
        const name = response.data.chatInterface.name;
        setDisplayName(name);
        setColor(response.data.chatInterface.color);
        setTopHeader(response.data.chatInterface.topHeader);
        setTopHeaderFont(response.data.chatInterface.topHeaderFont);
        setQuestionBubble(response.data.chatInterface.questionBubble);
        setQuestionBubbleFont(response.data.chatInterface.questionBubbleFont);
        setAnswerBubble(response.data.chatInterface.answerBubble);
        setAnswerBubbleFont(response.data.chatInterface.answerBubbleFont);
        setSendButton(response.data.chatInterface.sendButton);
        setMessageType(response.data.chatInterface.messageType);
        setBotIndexValue(response.data.chatInterface.chatBotIndexValue);
        if (response.data.chatInterface.logoName) {
         
          setChatBotImage(response.data.chatInterface.logoName);
          
          setSelectedImage(response.data.chatInterface.logoName)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [API_URL, API_VERSION]);

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <section className="settings">
          <Container>
            <Row className="justify-content-center">
              <Col lg={7} className="mb-4">
                <div className="settings_wrap">
                  <h3>{t("Chat Interface")}</h3>
                  <small className="text-secondary d-block pb-3">
                    Applies when embedded on a website
                  </small>
                  <form action="">
                  

                    <div className="form-group">
                      <label className="form-label">
                        {t("Display name")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Display name info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(event) => {
                          setDisplayName(event.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <label className="form-label mb-0">
                          {t("Initial Welcome Message")}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={
                              <Tooltip>
                                {t("Initial Welcome Message info")}
                              </Tooltip>
                            }
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                        <div
                          className="button py-1 px-3"
                          onClick={resetHandler}
                        >
                          {t("RESET")}
                        </div>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="exampleForm.ControlTextarea1"
                        value={initialMsg}
                        onChange={(event) => {
                          setInitialMsg(event.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        {t("Suggested Questions")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip>{t("Suggested Questions info")}</Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <textarea
                        rows="3"
                        id="exampleForm.ControlTextarea1"
                        className="form-control"
                        spellcheck="false"
                        value={suggestedMsg}
                        onChange={(event) => {
                          setSuggestedMsg(event.target.value);
                          setSuggestedQuestion(event.target.value.split("\n"));
                        }}
                      ></textarea>
                      <div className="form-text">
                        {t("Enter each message in a new line.")}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        {t("Chat bot image")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip>{t("Chat bot image info")}</Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        accept="image/*"
                        onChange={(event) => changeBotImage(event)}
                      />
                    </div>
                    <div className="defaultImages">
                      {/* Radio buttons for image selection */}
                      {imageList.map((image, index) => (
                        <label className="icon" key={index}>
                          <input
                            className="radioBtn"
                            type="radio"
                            name="selectedImage"
                            value={image}
                            checked={selectedImage === image}
                            onChange={(event) => changeBotImage(event)}
                          />
                          <img
                            src={process.env.PUBLIC_URL + `/images/${image}`}
                            alt={image}
                            className="radio-image"
                            //  style={{ height: "30px", width: "60px" }}
                          />
                        </label>
                      ))}
                    </div>
                    <div className="form-group">
                      <label className="form-label" for="">
                        {t("Chat bubble background color")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip>{t("Chat bubble background color info")}</Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <div className="d-flex align-items-center justify-content-start gap-3">
                        <input
                          type="color"
                          className="form-control form-control-color"
                          id="exampleColorInput"
                          value={bubbleChatIconColor}
                          title="Choose your color"
                          onChange={(event) =>
                            setBubbleColor(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label" for="">
                            {t("Top Header Color")}
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip>{t("Top Header Color info")}</Tooltip>
                              }
                            >
                              <span className="ms-2 text-primary">
                                <i class="fa-solid fa-circle-question"></i>
                              </span>
                            </OverlayTrigger>
                          </label>
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <input
                              type="color"
                              className="form-control form-control-color"
                              id="exampleColorInput"
                              value={topHeader}
                              title="Choose your color"
                              onChange={(event) =>
                                setTopHeader(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label" for="">
                            {t("Font Color")}
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip>
                                  {t("Top Header Font Color info")}
                                </Tooltip>
                              }
                            >
                              <span className="ms-2 text-primary">
                                <i class="fa-solid fa-circle-question"></i>
                              </span>
                            </OverlayTrigger>
                          </label>
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <input
                              type="color"
                              className="form-control form-control-color"
                              id="exampleColorInput"
                              value={topHeaderFont}
                              title="Choose your color"
                              onChange={(event) =>
                                setTopHeaderFont(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        {" "}
                        <div className="form-group">
                          <label className="form-label" for="">
                            {t("Question Bubble ")}
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip>{t("Question Bubble info")}</Tooltip>
                              }
                            >
                              <span className="ms-2 text-primary">
                                <i class="fa-solid fa-circle-question"></i>
                              </span>
                            </OverlayTrigger>
                          </label>
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <input
                              type="color"
                              className="form-control form-control-color"
                              id="exampleColorInput"
                              value={questionBubble}
                              title="Choose your color"
                              onChange={(event) =>
                                setQuestionBubble(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label" for="">
                            {t("Font Color")}
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip>
                                  {t("Question Bubble Font Color info")}
                                </Tooltip>
                              }
                            >
                              <span className="ms-2 text-primary">
                                <i class="fa-solid fa-circle-question"></i>
                              </span>
                            </OverlayTrigger>
                          </label>
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <input
                              type="color"
                              className="form-control form-control-color"
                              id="exampleColorInput"
                              value={questionBubbleFont}
                              title="Choose your color"
                              onChange={(event) =>
                                setQuestionBubbleFont(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label" for="">
                            {t("Answer Bubble Color")}
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip>
                                  {t("Answer Bubble Color info")}
                                </Tooltip>
                              }
                            >
                              <span className="ms-2 text-primary">
                                <i class="fa-solid fa-circle-question"></i>
                              </span>
                            </OverlayTrigger>
                          </label>
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <input
                              type="color"
                              className="form-control form-control-color"
                              id="exampleColorInput"
                              value={answerBubble}
                              title="Choose your color"
                              onChange={(event) =>
                                setAnswerBubble(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label" for="">
                            {t("Font Color")}
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip>
                                  {t("Answer Bubble Font Color info")}
                                </Tooltip>
                              }
                            >
                              <span className="ms-2 text-primary">
                                <i class="fa-solid fa-circle-question"></i>
                              </span>
                            </OverlayTrigger>
                          </label>
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <input
                              type="color"
                              className="form-control form-control-color"
                              id="exampleColorInput"
                              value={answerBubbleFont}
                              title="Choose your color"
                              onChange={(event) =>
                                setAnswerBubbleFont(event.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" for="">
                        {t("Send Button Color")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip>{t("send button Color info")}</Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <div className="d-flex align-items-center justify-content-start gap-3">
                        <input
                          type="color"
                          className="form-control form-control-color"
                          id="exampleColorInput"
                          value={sendButton}
                          title="Choose your color"
                          onChange={(event) =>
                            setSendButton(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <label className="form-label mb-0">
                          {t("Bubble height (in pixel)")}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={<Tooltip>{t("Bubble height info")}</Tooltip>}
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                        <label className="form-label mb-0">
                          {t("Bubble width (in pixel)")}
                          <OverlayTrigger
                            key="right"
                            placement="left"
                            overlay={
                              <Tooltip>
                                {t("Bubble width info")}
                              </Tooltip>
                            }
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <input
                            className="form-control"
                            type="text"
                            name="bubbleText"
                            id="bubbleText"
                            value={bubbleHeight}
                            onChange={(event) => {
                              setBubbleHeight(event.target.value);
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            type="number"
                            name="bubbleDisappearSec"
                            id="bubbleDisappearSec"
                            value={bubbleWidth}
                            onChange={(event) => {
                              setBubbleWidth(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <label className="form-label mb-0">
                          {t("Bubble Text")}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={<Tooltip>{t("Bubble Text info")}</Tooltip>}
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                        <label className="form-label mb-0">
                          {t("Sec")}
                          <OverlayTrigger
                            key="right"
                            placement="left"
                            overlay={
                              <Tooltip>
                                {t("Bubble Disappearing Sec info")}
                              </Tooltip>
                            }
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <input
                            className="form-control"
                            type="text"
                            name="bubbleText"
                            id="bubbleText"
                            value={BubbleName}
                            onChange={(event) => {
                              setBubbleText(event.target.value);
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <input
                            className="form-control"
                            type="number"
                            name="bubbleDisappearSec"
                            id="bubbleDisappearSec"
                            value={BubbleSec}
                            onChange={(event) => {
                              setBubbleDisappearSec(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("Align Chat Bubble Button")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip>
                              {t("Align Chat Bubble Button info")}
                            </Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={btnAlign}
                        onChange={(event) => setBtnAlign(event.target.value)}
                      >
                        <option selected value="Bottom_Right">
                          Bottom Right
                        </option>
                        <option value="Top_Right">Top Right</option>
                        <option value="Middle_Right">Middle Right</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("Placeholder question")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Placeholder question")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="messageType"
                        id="messageType"
                        placeholder="Type here"
                        value={messageType}
                        onChange={(event) => {
                          setMessageType(event.target.value);
                        }}
                      />
                    </div>
                    
    <div className="form-group">
                    <label className="form-label">
                      {t("Chat bot zindex value")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                          <Tooltip>
                            {t("Chat bot zindex value info")}
                          </Tooltip>
                        }
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="indexCount"
                      id="botIndexValue"
                      value={botIndexValue}
                      onChange={(e) => setBotIndexValue(e.target.value)}
                    />
                  </div>
                    <input
                      type="button"
                      className="button"
                      value={t("SUBMIT")}
                      onClick={(e) => updateBot(e)}
                    />
                  </form>
                </div>
              </Col>
              <Col lg={5}>
                <section className="chating_box">
                  <Container>
                    <Row className="justify-content-center">
                      <Col md={12}>
                        <div className="chatingwrap">
                          <div
                            className="chating_head"
                            style={{ background: topHeader }}
                          >
                            <div
                              className="brand"
                              style={{ color: topHeaderFont }}
                            >
                              {name}
                            </div>
                            <div className="refresh">
                              <i className="fa-solid fa-close"></i>
                            </div>
                          </div>
                          <div className="chating_area">
                            <ul>
                              <li
                                className="sender"
                                style={{ background: answerBubble }}
                              >
                                <div
                                  className="cont"
                                  style={{ color: answerBubbleFont }}
                                >
                                  <span>{initialMsg}</span>
                                </div>
                              </li>
                              <li
                                className="repaly"
                                style={{ background: questionBubble }}
                              >
                                <div
                                  className="cont"
                                  style={{ color: questionBubbleFont }}
                                >
                                  <span>Hi, I want to go goa by plan.</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="sugbox">
                            {suggestedQuestion &&
                              suggestedQuestion.map((question) => {
                                return <span>{question}</span>;
                              })}
                          </div>
                          <div className="chating_foot">
                            <form action="">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="message…"
                                placeholder={messageType}
                              />
                              <button
                                type="button"
                                className="button"
                                style={{ background: sendButton}}                                
                                >
                                <i
                                  className="fa fa-paper-plane"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </form>
                          </div>
                        </div>
                        {/* <div className="chat_icon">
                          <div className="icon">
                            <img
                              src={
                                chatBotImage
                                  ? chatBotImage
                                  : "https://seekicon.com/free-icon-download/chat_7.svg"
                              }
                              alt=""
                            />
                          </div>
                        </div> */}
                    <div className="chat_icon">
                          <div className="chat_bubble">
                            <strong id="chat_bubble">{BubbleName}</strong>
                          </div>
                          <div
                            className="icon"
                            style={{
                              backgroundColor: bubbleChatIconColor,
                              height: `${bubbleHeight}px`,
                              width: `${bubbleWidth}px`,
                            }}
                          >
                            <img
                              src={
                                selectedImage
                                  ? `/images/${selectedImage}`
                                  : chatBotImage
                                  ? chatBotImage
                                  : "../images/chat-icon-double-black-white.svg"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ChatBotInterface;
