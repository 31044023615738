// import React, { useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import { Navigate, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { login } from "../../redux/adminUser";
// import axios from "axios";
// import ReactTost from "../ReactTost";

// const Login = () => {
//   const [type, setType] = useState("");
//   const [message, setMessage] = useState("");
//   const [showAlert, setShowAlert] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const API_URL = process.env.REACT_APP_API_URL;
//   const API_VERSION = process.env.REACT_APP_API_VERSION;

//   const onChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "username") {
//       setEmail(value);
//     } else if (name === "password") {
//       setPassword(value);
//     }
//   };
//   const isLoggedIn = useSelector((state) => state.user.userData);
//   const goToLogin = async (e) => {
//     e.preventDefault();
//     if (email && password) {
//       // Perform login logic here
//       return await axios
//         .post(`${API_URL}/${API_VERSION}/auth/login`, {
//           email,
//           password,
//         })
//         .then((response) => {
//           if (response.data.user.role === "admin") {
//             localStorage.setItem("isLogin", true);
//             localStorage.setItem("isRole", response.data.user.role);
//             localStorage.setItem("EMAIL", response.data.user.email);
//             localStorage.setItem("NAME", response.data.user.name);
//             localStorage.setItem("token", response.data.tokens.access.token);
//             dispatch(
//               login({
//                 userData: true,
//                 IsUserLevel: response.data.user.role,
//               })
//             );
//             navigate("dashboard");
//           } 
//           else if(response.data.user.isEmailVerified===false){
//             setType("Danger");
//             setMessage('Please verify your email and try again');
//             setShowAlert(true);
//             navigate("/admin");
//           }else {
//             setType("Danger");
//             setMessage("You are not allowed.");
//             setShowAlert(true);
//           }
//         })
//         .catch((error) => {
//           setType("Danger");
//             setMessage('invalid username and password');
//             setShowAlert(true);
//         });
//     }
//   };

//   return !isLoggedIn ? (
//     <section className="login_main">
//       <ReactTost
//         onHide={() => {
//           setShowAlert(false);
//           setType("");
//           setMessage("");
//         }}
//         alertClass={type}
//         alertMessage={message}
//         alertDo={showAlert}
//       />
//       :
//       <div className="login_form">
//         <Container>
//           <Row className="justify-content-center">
//             <Col xxl={5} xl={6} lg={6} md={8} sm={10}>
//               <div className="boxwrap">
//                 <h1>Login</h1>
//                 <p className="mb-3">Sign in to your account to continue</p>
//                 <Col md={12}>
//                   <div className="input-group mb-3">
//                     <span className="input-group-text">
//                       <i className="fa-solid fa-envelope"></i>
//                     </span>
//                     <input
//                       type="text"
//                       placeholder="Username"
//                       name="username"
//                       className="form-control"
//                       onChange={onChange}
//                     />
//                   </div>
//                 </Col>
//                 <Col md={12}>
//                   <div className="input-group mb-3">
//                     <span className="input-group-text">
//                       <i className="fa-solid fa-lock"></i>
//                     </span>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Password"
//                       name="password"
//                       onChange={onChange}
//                     />
//                   </div>
//                 </Col>
//                 <div className="d-flex align-items-center flex-lg-row flex-column">
//                   <div className="text-start mb-4 order-lg-0 order-1">
//                     <input
//                       type="submit"
//                       value="Sign in"
//                       className="button"
//                       onClick={goToLogin}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </section>
//   ) : (
//     <Navigate to="/admin/dashboard" />
//   );
// };

// export default Login;
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/adminUser";
import axios from "axios";
import ReactTost from "../ReactTost";

const Login = () => {
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const isLoggedIn = useSelector((state) => state.user.userData);
  const goToLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required");
    }
    if (!password) {
      setPasswordError("Password is required");
    }

    if (email && password) {
      // Perform login logic here
      try {
        const response = await axios.post(`${API_URL}/${API_VERSION}/auth/login`, {
          email,
          password,
        });

        if (response.data.user.role === "admin") {
          localStorage.setItem("isLogin", true);
          localStorage.setItem("isRole", response.data.user.role);
          localStorage.setItem("EMAIL", response.data.user.email);
          localStorage.setItem("NAME", response.data.user.name);
          localStorage.setItem("token", response.data.tokens.access.token);
          dispatch(
            login({
              userData: true,
              IsUserLevel: response.data.user.role,
            })
          );
          navigate("/admin/dashboard");
        } else if (response.data.user.isEmailVerified === false) {
          setType("Danger");
          setMessage("Please verify your email and try again");
          setShowAlert(true);
          navigate("/admin");
        } else {
          setType("Danger");
          setMessage("You are not allowed.");
          setShowAlert(true);
        }
      } catch (error) {
        setType("Danger");
        setMessage("Invalid username and password");
        setShowAlert(true);
      }
    }
  };

  return !isLoggedIn ? (
    <section className="login_main">
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <div className="login_form">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={5} xl={6} lg={6} md={8} sm={10}>
              <div className="boxwrap">
                <h1>Login</h1>
                <p className="mb-3">Sign in to your account to continue</p>
                <Col md={12}>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      className="form-control"
                      onChange={onChange}
                    />
                   
                  </div>
                  {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                </Col>
                <Col md={12}>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa-solid fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      onChange={onChange}
                    />
                  
                  </div>
                  {passwordError && (
                      <div className="text-danger">{passwordError}</div>
                    )}
                </Col>
                <div className="d-flex align-items-center flex-lg-row flex-column">
                  <div className="text-start mb-4 order-lg-0 order-1">
                    <input
                      type="submit"
                      value="Sign in"
                      className="button"
                      onClick={goToLogin}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  ) : (
    <Navigate to="/admin/dashboard" />
  );
};

export default Login;
