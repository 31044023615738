import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../Loader";

const PlansList = () => {
  const [plans, setPlans] = useState([]);
  const [status, setStatus] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loader, setLoader] = useState(false);
  async function getPlans() {
    setLoader(true);
    await axios
      .get(`${API_URL}/${API_VERSION}/plan`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoader(false);
        setStatus(response.data.status);
        setPlans(response.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  }

  function updatePlan(e, id) {
    const newIsActive = e.target.checked;
    axios
      .get(`${API_URL}/${API_VERSION}/planStatus/${id}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // setUser(response.data);
        setStatus(response.data.status);
        //setUserDelete(false);
      })
      .catch((error) => {
        // console.log(error);
      });
    //setId(id);
    setStatus(newIsActive);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/planStatus/${id}`,
        {
          newIsActive,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setStatus(newIsActive);
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <section className="clients_main">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <div className="head_title">
                        <h3 className="mb-0">Plans</h3>
                      </div>
                      <Link className="button" to="/admin/add-plan">
                        <i className="fa-solid fa-circle-plus"></i> Add Plan
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <table className="client_table">
                      <thead>
                        <tr className="d-none d-lg-table-row">
                          <th width="5%">Position</th>
                          <th width="11%">Title</th>
                          <th width="12%">Price</th>
                          <th width="11%">Frequency</th>
                          <th width="11%">Bots</th>
                          <th width="11%">Questions</th>
                          <th width="11%">Scan URLS</th>
                          <th width="11%">File uploads</th>
                          <th width="11%">File Size</th>
                          <th width="11%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {plans &&
                          plans.map((plan, index) => (
                            <tr className="tr" key={index}>
                              <td>
                                <strong className="ht">Position : </strong>
                                <p>{plan.position}</p>
                              </td>
                              <td>
                                <strong className="ht">Title : </strong>
                                <p>{plan.name}</p>
                              </td>
                              <td>
                                <strong className="ht">Prices : </strong>
                                <p>{plan.price}</p>
                              </td>
                              <td>
                                <strong className="ht">frequency : </strong>
                                <p>{plan.duration}</p>
                              </td>
                              <td>
                                <strong className="ht">Bots: </strong>
                                <p>{plan.maxBotCount}</p>
                              </td>
                              <td>
                                <strong className="ht">Questions : </strong>
                                <p>{plan.maxQuestionCount}</p>
                              </td>
                              <td>
                                <strong className="ht">Scan URLS : </strong>
                                <p>{plan.maxUrlCount}</p>
                              </td>
                              <td>
                                <strong className="ht">File uploads : </strong>
                                <p>{plan.maxFileCount}</p>
                              </td>
                              <td>
                                <strong className="ht">File Size : </strong>
                                <p>{plan.maxFileSize} MB</p>
                              </td>
                              <td>
                                <strong className="ht">Action : </strong>
                                <p className="d-flex gap-2">
                                  <Link
                                    className="button"
                                    to={`/admin/add-plan/${plan.id}`}
                                  >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                  </Link>
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id={plan.id}
                                      defaultChecked={plan.status}
                                      onChange={(e) => updatePlan(e, plan.id)}
                                    />
                                  </Form>
                                </p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default PlansList;
