import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import ReactTost from "../../ReactTost";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../../redux/adminUser";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import Loader from "../../Loader";
import UserPlanDelete from "../../User/Modal/UserSubscriptionDelete";
import UserSubscriptionDelete from "../Modal/UserSubscriptionDelete";

const EditClient = () => {
  const { id } = useParams();
  const [monthList, setMonthList] = useState([]);
  const [planId, setPlanId] = useState("");
  const { t } = useTranslation();
  const [password, setPassword] = useState();
  const [activePlan, setActivePlan] = useState([]);
  const [date, setDate] = useState();
  const [confirm_password, setConfirmPassword] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [plan, setPlan] = useState();
  const [userLatestPlanHistory, setUserLatestPlanHistory] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loader, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    website: "",
    password: "",
    confirmPassword: "",
    condition: "",
  });
  function isPasswordStrong(password) {
    // Define your password strength criteria here
    const minLength = 8;
    const maxLength = 20;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*]/.test(password); // You can add more special characters as needed

    return (
      password.length >= minLength &&
      password.length <= maxLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  }

  function ChangePassword(e) {
    e.preventDefault();
    setNewPasswordError("");
    setConfirmPasswordError("");
    const language = localStorage.getItem('i18nextLng');
    if (!password || !confirm_password) {
      // Handle the case where either new_password or confirm_password is empty
      setNewPasswordError("Password cannot be empty");
      setConfirmPasswordError("Password cannot be empty");
      return false;
    }

    if (password !== confirm_password) {

      setNewPasswordError(" Password and confirm password must match");
      setConfirmPasswordError("Password and confirm password must match")
      return false;
    }

    if (!isPasswordStrong(password)) {

      setNewPasswordError("Your password must be 8-20 characters long, contain letters, numbers and special characters");
      return false;
    }

    if (password) {
      if (password !== confirm_password) {
        setType("Danger");
        setMessage("Password and confirm password not match.");
        setShowAlert(true);
        return false;
      } else {
        setLoading(true);
        axios
          .patch(
            `${API_URL}/${API_VERSION}/changePassword/${id}`,
            {
              password,
            },
            {
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            setType("Success");
            setMessage("Password is changed.");
            setShowAlert(true);
            // setTimeout(() => {
            //   dispatch(logout());
            //   localStorage.clear();
            //   navigate("/");
            // }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            setType("Danger");
            setMessage(
              error.response.data.message
                ? error.response.data.message
                : "Something went to wrong."
            );
            setShowAlert(true);
          });
      }
    }
  }

  function UpdateUser() {
    //setLoading(true);
    const errors = {};

    if (!name) {
      errors.name = "Name is required.";
    }
    if (!phone) {
      errors.phone = "Phone is required.";
    }
    if (!email) {
      errors.email = "Email is required.";
    }
    if (!company) {
      errors.company = "Company is required.";
    }
    if (!website) {
      errors.website = "Website is required.";
    }
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    setLoading(true)
    axios
      .patch(
        `${API_URL}/${API_VERSION}/user/${id}`,
        {
          name,
          email,
          phone,
          company,
          website,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setType("Success");
        setMessage("Profile updated.");
        setShowAlert(true);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "Something went to wrong."
        );
        setShowAlert(true);
      });
  }

  function getActivePlan() {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${id}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setActivePlan(response.data.plan);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "Something went to wrong."
        );
        setShowAlert(true);
      });
  }

  async function postExpireDate() {
    const expireDateByAdmin = date;
    const plan = planId;
    setLoading(true);
    axios
      .post(
        `${API_URL}/${API_VERSION}/user/plansList/${id}`,
        {
          expireDateByAdmin,
          plan,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        getActivePlan();
        setType("Success");
        setMessage("Plan updated.");
        setShowAlert(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getActivePlan();
    setLoading(true);

    axios
      .get(`${API_URL}/${API_VERSION}/user/${id}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {

        setName(response.data.name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setCompany(response.data.company);
        setWebsite(response.data.website);
        setDate(response.data.expireDateByAdmin);
        setPlanId(response.data.plan ? response.data.plan.id : "");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "Something went to wrong."
        );
        setShowAlert(true);
      });
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/plan`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setPlan(response.data);
        setMonthList(
          response.data.filter((month) => month.duration === "month")
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    getLatestPaymentHistory();
  }, [id, API_URL, API_VERSION]);

  function deleteSubscription(e) {
    setDeleteModalShow(true)
    // e.preventDefault();
    // setLoading(true);
    // axios
    //   .delete(`${API_URL}/${API_VERSION}/user/active-plan/${id}`, {
    //     headers: {
    //       accept: "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     setLoading(false);
    //     getActivePlan();
    //     setType("Success");
    //     setMessage("Subscription deleted.");
    //     setShowAlert(true);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     setType("Danger");
    //     setMessage(
    //       error.response.data.message
    //         ? error.response.data.message
    //         : "Something went to wrong."
    //     );
    //     setShowAlert(true);
    //   });
  }

  function getLatestPaymentHistory() {
    axios
      .get(`${API_URL}/${API_VERSION}/users/new-payment-history/${id}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setUserLatestPlanHistory(response.data);
      })
      .catch((err) => console.log(err));
  }
  function handleInputChange(field) {

    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  }
  return (
    <>
      <section className="userdoc_main">
        <ReactTost
          onHide={() => {
            setShowAlert(false);
            setType("");
            setMessage("");
          }}
          alertClass={type}
          alertMessage={message}
          alertDo={showAlert}
        />

        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="head_title">
                  <h1>{t("Edit Client")}</h1>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <Card>
                <Card.Body className="p-4">
                  <h4>{t("Edit Client")}</h4>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <label for="" className="form-label">
                          {t("Name")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value)
                            handleInputChange('name')
                          }}
                        />
                        {fieldErrors.name && <div className="error" style={{ color: 'red' }}>{fieldErrors.name}</div>}

                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label for="" className="form-label">
                          {t("Phone")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          value={phone}
                          onChange={(event) => {
                            setPhone(event.target.value)
                            handleInputChange('phone')
                          }}
                        />
                        {fieldErrors.phone && <div className="error" style={{ color: 'red' }}>{fieldErrors.phone}</div>}

                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label for="" className="form-label">
                          {t("Email")}
                        </label>
                        <input
                          readOnly
                          disabled
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          value={email}
                        // onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label for="" className="form-label">
                          {t("Company")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company"
                          name="company"
                          value={company}
                          onChange={(event) => {
                            setCompany(event.target.value)
                            handleInputChange('company')
                          }}
                        />
                        {fieldErrors.company && <div className="error" style={{ color: 'red' }}>{fieldErrors.company}</div>}

                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label for="" className="form-label">
                          {t("Website")}
                        </label>
                        <input
                          type="url"
                          className="form-control"
                          id="website"
                          name="website"
                          value={website}
                          onChange={(event) => {
                            setWebsite(event.target.value)
                            handleInputChange('website')
                          }}
                        />
                        {fieldErrors.website && <div className="error" style={{ color: 'red' }}>{fieldErrors.website}</div>}

                      </div>
                    </Col>
                    {loader ? (
                      <Loader />
                    ) : (
                      <Col md={12} className="text-end">
                        <input
                          type="button"
                          value={t("UPDATE")}
                          className="userbutton mt-3"
                          onClick={(e) => UpdateUser(e)}
                        />
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Row>
                {loader ? (
                  <Loader />
                ) : (
                  <Col md={12}>
                    <Card>
                      {activePlan && (
                        <Card.Body>
                          <div className="d-flex flex-md-row flex-column gap-md-0 gap-4 align-items-center justify-content-between">
                            <h3 className="m-0">
                              {t(activePlan.name)}/{t(activePlan.duration)}
                            </h3>

                            {activePlan && activePlan.price && (
                              <Button
                                variant="danger"
                                className="userbutton bg-danger"
                                onClick={(e) => deleteSubscription(e)}
                              >
                                {t("CANCEL NOW")}
                              </Button>
                            )}
                          </div>
                          <div className="mt-4">
                            <div className="plan_box">
                              <div className="ribbon">
                                <span className="rib">Current Plan</span>
                              </div>
                              <div className="name">
                                <strong>{activePlan.description}</strong>
                              </div>
                              <div className="prices">
                                <h1>
                                  $ {activePlan.price && activePlan.price}
                                </h1>
                              </div>
                              <div className="list">
                                <ul>
                                  <li>
                                    <span>{activePlan.maxBotCount} bots</span>
                                  </li>
                                  <li>
                                    <span>
                                      {activePlan.maxQuestionCount} Question
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      {activePlan.maxUrlCount} Scan URL
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      {activePlan.maxFileCount} Files upload
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      {activePlan.maxFileSize} mb Size per file
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      )}
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                <Col md={6} className="mb-4">
                  <Card>
                    <Card.Body className="p-4">
                      <h4>{t("Change password")}</h4>
                      <Row>
                        <Col md={12}>
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {t("New password")}
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="newPassword"
                              name="newPassword"
                              onChange={(event) =>{
                                setPassword(event.target.value)
                                handleInputChange('newPasswordError')}
                              }
                            />
                            {newPasswordError && (
                              <small className="text-danger">{newPasswordError}</small>
                            )}
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {t("Confirm password")}
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="confirmPassword"
                              name="confirmPassword"
                              onChange={(event) =>
                                setConfirmPassword(event.target.value)
                              }
                            />
                            {confirmPasswordError && (
                              <small className="text-danger">{confirmPasswordError}</small>
                            )}
                          </div>
                        </Col>
                        {loader ? (
                          <Loader />
                        ) : (
                          <Col md={12} className="text-end">
                            <button
                              className="userbutton mt-3"
                              onClick={(e) => ChangePassword(e)}
                            >
                              {t("Change")}
                            </button>
                          </Col>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className="mb-4">
                  <Card>
                    <Card.Body className="p-4">
                      <h4>{t("Plan expiration date")}</h4>
                      <Row>
                        <Col md={12}>
                          <div className="form-group mb-3">
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Plan
                                <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                value={planId}
                                onChange={(e) => setPlanId(e.target.value)}
                              >
                                {plan &&
                                  plan.filter((plans) => plans.status === true).map((plans) => (
                                    <option value={plans.id}>
                                      {plans.name} - {plans.duration} -{" "}
                                      {plans.price}{" "}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="form-group mb-3">
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Expire Date
                                <span
                                  style={{ color: "red", marginLeft: "2px" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                value={date}
                                onChange={(event) =>
                                  setDate(event.target.value)
                                }
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        {loader ? (
                          <Loader />
                        ) : (
                          <Col md={12} className="text-end">
                            <button
                              className="userbutton mt-3"
                              onClick={(e) => postExpireDate(e)}
                            >
                              {t("Update")}
                            </button>
                          </Col>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Body className="p-4">
                      <h4>{t("Payment")}</h4>
                      {userLatestPlanHistory && (
                        <>
                          <div className="mb-3">
                            <Row>
                              <Col md={4}>
                                <strong>{t("Date/Time")}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>{t("Amount")}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>{t("Plan")}</strong>
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col md={4}>
                                <strong>{userLatestPlanHistory.dt}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>{userLatestPlanHistory.price}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>
                                  {userLatestPlanHistory.plan.name}
                                </strong>
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <strong>{t("Customer ID")} : </strong>
                            {userLatestPlanHistory.customer}
                          </div>
                          <div className="mb-3">
                            <strong>{t("Subscription ID")} : </strong>
                            {userLatestPlanHistory.subscription}
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <UserSubscriptionDelete
        show={deleteModalShow}
        onHide={() => {
          setDeleteModalShow(false)
          getActivePlan()
        }}
      />
    </>
  );
};

export default EditClient;
