import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import UserLanguage from "../UserLanguage";
import { logout } from "../../../redux/adminUser";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const [supportLink, setSupportLink] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function UserLogout(e) {
    e.preventDefault();
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  }

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "en") {
      setSupportLink("https://chatdocx.ai/en/support");
    } else {
      setSupportLink("https://chatdocx.ai/de/support");
    }
  }, [localStorage.getItem("i18nextLng")]);
  return (
    <>
      <div id="header">
        <Container>
          <Navbar expand="lg">
            <Container fluid>
              <Link className="brand-logo" to="/user-bots">
                <img src="../../images/ChatDocX_logo.svg" alt="" width={127} />
              </Link>
              <div className="d-lg-none d-block ms-auto">
                <UserLanguage claval="user_head" />
              </div>
              <Navbar.Toggle aria-controls="navbarScroll">
                <i className="fa-solid fa-bars-staggered"></i>
              </Navbar.Toggle>

              <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
                  <Link className="nav-link" to="/user-bots">
                    {t("My Chatbots")}
                  </Link>
                  <Link className="nav-link" to="/user-doc">
                    {t("My Files")}
                  </Link>
                  <Link className="nav-link" to="/my-profile">
                    {t("My Profile")}
                  </Link>
                  <Link className="nav-link" to="/plans">
                    {t("Plans")}
                  </Link>
                  <a
                    className="nav-link"
                    href={supportLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Support")}
                  </a>

                  {/* <Link className="nav-link" to="/chat-history">
                    {t("Chat History")}
                  </Link> */}
                  <Link
                    className="nav-link d-inline-block d-lg-none"
                    onClick={(e) => UserLogout(e)}
                  >
                    {t("Logout")}
                  </Link>
                </Nav>
                <Nav
                  className="ms-auto my-2 my-lg-0 d-lg-flex d-none"
                  navbarScroll
                >
                  <UserLanguage claval="user_head" />
                  <button className="userbutton" onClick={(e) => UserLogout(e)}>
                    {t("Logout")}
                  </button>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      </div>
    </>
  );
};

export default Header;
