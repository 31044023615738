import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteClient = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete your Client?
          </p>
        </Modal.Body>
        <Modal.Footer>
        
          <Button variant="danger" onClick={props.onDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteClient;
