import React, { useEffect, useState } from "react";
import i18next from "i18next";

const UserLanguage = (props) => {
  const [nameClass, setNameClass] = useState();
  const [userSelectedLang, setUserSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );
  function LanguageChange(lang) {
    i18next.changeLanguage(lang);
    setUserSelectedLang(lang);
  }
  useEffect(() => {
    if (props !== undefined) {
      setNameClass(props.claval);
    }
  }, [props]);
  return (
    <>
      <div id="languageswitcher" className={nameClass}>
        <div className="cust-check-group">
          <div className="cust-check">
            <label className="form-check-label" for="English">
              <input
                className="form-check-input"
                type="radio"
                value=""
                name="language"
                id="English"
                checked={userSelectedLang === "en"}
                onChange={() => LanguageChange("en")}
              />
              <img src=" ../images/EN.svg" alt="" />
            </label>
          </div>
          <div className="cust-check">
            <label className="form-check-label" for="Germany">
              <input
                className="form-check-input"
                type="radio"
                value=""
                name="language"
                id="Germany"
                checked={userSelectedLang === "de"}
                onChange={() => LanguageChange("de")}
              />
              <img src="../images/DE.svg" alt="" />
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLanguage;
