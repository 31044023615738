import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactTost from "../ReactTost";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

function ResendVerifyEmail() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  //const language=localStorage.getItem('i18nextLng'==='de')? 'german' : 'english'

  useEffect(() => {
    const token = id;
    const language = localStorage.getItem("i18nextLng" === "de")
      ? "german"
      : "english";
    // console.log(language);
    axios
      .post(`${API_URL}/${API_VERSION}/auth/verifyEmail`, {
        token,
        language,
      })
      .then((response) => {
        setLoading(false);
        setType("Success");
        setMessage(t("Your are verify."));
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(t("Something went wrong."));
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      });
  }, [id, API_URL, API_VERSION, navigate]);
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? <Loader /> : ""}
    </>
  );
}

export default ResendVerifyEmail;
