import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import ReactTost from "../ReactTost";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/adminUser";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import UserPlanDelete from "./Modal/UserSubscriptionDelete";

const MyProfile = () => {
  const user = localStorage.getItem("id");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setOldPassword] = useState();
  const [new_password, setNewPassword] = useState();
  const [confirm_password, setConfirmPassword] = useState();
  const [activePlan, setActivePlan] = useState([]);
  const [userLatestPlanHistory, setUserLatestPlanHistory] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    website: "",
    password: "",
    confirmPassword: "",
    condition: "",
  });
  function isPasswordStrong(password) {
    const minLength = 8;
    const maxLength = 20;
    const hasUpperCase = /[A-Z]/.test(new_password);
    const hasLowerCase = /[a-z]/.test(new_password);
    const hasNumbers = /\d/.test(new_password);
    const hasSpecialChars = /[!@#$%^&*]/.test(new_password);

    return (
      new_password &&
      new_password.length >= minLength &&
      new_password.length <= maxLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  }

  function ChangePassword(e) {
    e.preventDefault();
    setNewPasswordError("");
    setConfirmPasswordError("");
    const language = localStorage.getItem("i18nextLng");
    if (!new_password || !confirm_password) {
      // Handle the case where either new_password or confirm_password is empty
      setNewPasswordError(t("Password cannot be empty"));
      setConfirmPasswordError(t("Password cannot be empty"));
      return false;
    }

    if (new_password !== confirm_password) {
      setNewPasswordError(t(" Password and confirm password must match"));
      setConfirmPasswordError(t("Password and confirm password must match"));
      return false;
    }

    if (!isPasswordStrong(new_password)) {
      setNewPasswordError(
        t(
          "Your password must be 8-20 characters long, contain letters, numbers and special characters"
        )
      );
      return false;
    }

    if (
      password &&
      confirm_password &&
      new_password &&
      new_password === confirm_password
    ) {
      setLoading(true);
      axios
        .post(
          `${API_URL}/${API_VERSION}/user/change-password`,
          {
            password,
            new_password,
            confirm_password,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setType("Success");
          setMessage(t("Password is changed."));
          setShowAlert(true);
        })
        .catch((error) => {
          setLoading(false);
          setType("Danger");
          setMessage(t("Invalid current password"));

          setShowAlert(true);
        });
    }
  }

  function handleInputChange(field) {
    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  }
  function UpdateUser() {
    // setLoading(true);

    const errors = {};

    if (!name) {
      errors.name = t("Name is required.");
    }
    if (!phone) {
      errors.phone = t("Phone is required.");
    }
    if (!email) {
      errors.email = t("Email is required.");
    }
    if (!company) {
      errors.company = t("Company is required.");
    }
    if (!website) {
      errors.website = t("Website is required.");
    }
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    axios
      .patch(
        `${API_URL}/${API_VERSION}/user/${user}`,
        {
          name,
          email,
          phone,
          company,
          website,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setType("Success");
        setMessage(t("Profile updated."));
        setShowAlert(true);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : t("Something went to wrong.")
        );
        setShowAlert(true);
      });
  }

  useEffect(() => {
    getActivePlan();
    getLatestPaymentHistory();
    setLoading(true);

    axios
      .get(`${API_URL}/${API_VERSION}/user/${user}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setName(response.data.name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setCompany(response.data.company);
        setWebsite(response.data.website);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : t("Something went to wrong.")
        );
        setShowAlert(true);
      });
  }, [user, API_URL, API_VERSION]);

  function DeletePlan(id) {
    setDeleteModalShow(true);
  }

  function getActivePlan() {
    axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${user}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setActivePlan(response.data.plan);
      })
      .catch((error) => {
        setType("Danger");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : t("Something went to wrong.")
        );
        setShowAlert(true);
      });
  }
  function getLatestPaymentHistory() {
    axios
      .get(`${API_URL}/${API_VERSION}/users/new-payment-history/${user}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserLatestPlanHistory(response.data);
      })
      .catch((err) => {
        // console.log(err)
      });
  }
  return (
    <>
      <section className="userdoc_main">
        <ReactTost
          onHide={() => {
            setShowAlert(false);
            setType("");
            setMessage("");
          }}
          alertClass={type}
          alertMessage={message}
          alertDo={showAlert}
        />
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="head_title">
                  <h1>{t("My Profile")}</h1>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
              <Row>
                <Col md={12} className="mb-4">
                  <Card>
                    <Card.Body className="p-4">
                      <h4>{t("Edit profile")}</h4>
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              {t("Name")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              value={name}
                              onChange={(event) => {
                                setName(event.target.value);
                                handleInputChange("name");
                              }}
                            />
                            {fieldErrors.name && (
                              <div className="error" style={{ color: "red" }}>
                                {fieldErrors.name}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              {t("Phone")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              name="phone"
                              value={phone}
                              onChange={(event) => {
                                setPhone(event.target.value);
                                handleInputChange("phone");
                              }}
                            />
                            {fieldErrors.phone && (
                              <div className="error" style={{ color: "red" }}>
                                {fieldErrors.phone}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              {t("Email")}
                            </label>
                            <input
                              readOnly
                              disabled
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              value={email}
                              onChange={(event) => {
                                setEmail(event.target.value);
                                handleInputChange("email");
                              }}
                            />
                            {fieldErrors.email && (
                              <div className="error" style={{ color: "red" }}>
                                {fieldErrors.email}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              {t("Company")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="company"
                              name="company"
                              value={company}
                              onChange={(event) => {
                                setCompany(event.target.value);
                                handleInputChange("company");
                              }}
                            />
                            {fieldErrors.company && (
                              <div className="error" style={{ color: "red" }}>
                                {fieldErrors.company}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              {t("Website")}
                            </label>
                            <input
                              type="url"
                              className="form-control"
                              id="website"
                              name="website"
                              value={website}
                              onChange={(event) => {
                                setWebsite(event.target.value);
                                handleInputChange("website");
                              }}
                            />
                            {fieldErrors.website && (
                              <div className="error" style={{ color: "red" }}>
                                {fieldErrors.website}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={12} className="text-end">
                          {loading ? (
                            <Loader />
                          ) : (
                            <input
                              type="button"
                              value={t("UPDATE")}
                              className="userbutton mt-3"
                              onClick={(e) => UpdateUser(e)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-4">
                  <Card>
                    <Card.Body className="p-4">
                      <h4>{t("Payment")}</h4>
                      {userLatestPlanHistory && (
                        <>
                          <div className="mb-3">
                            <Row>
                              <Col md={4}>
                                <strong>{t("Date/Time")}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>{t("Amount")}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>{t("Plan")}</strong>
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <Row>
                              <Col md={4}>
                                <strong>{userLatestPlanHistory.dt}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>{userLatestPlanHistory.price}</strong>
                              </Col>
                              <Col md={4}>
                                <strong>
                                  {userLatestPlanHistory.plan.name} (
                                  {userLatestPlanHistory.status})
                                </strong>
                              </Col>
                            </Row>
                          </div>
                          <div className="mb-3">
                            <strong>{t("Customer ID")} : </strong>
                            {userLatestPlanHistory.customer}
                          </div>
                          <div className="mb-3">
                            <strong>{t("Subscription ID")} : </strong>
                            {userLatestPlanHistory.subscription}
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12} className="mb-4">
                  <Card>
                    <Card.Body className="p-4">
                      <h4>{t("Change password")}</h4>
                      <Row>
                        <Col md={12}>
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {t("Old password")}
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="oldPassword"
                              name="oldPassword"
                              onChange={(event) =>
                                setOldPassword(event.target.value)
                              }
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {t("New password")}
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="newPassword"
                              name="newPassword"
                              onChange={(event) => {
                                setNewPassword(event.target.value);
                                handleInputChange("new_password");
                              }}
                            />
                            {newPasswordError && (
                              <small className="text-danger">
                                {newPasswordError}
                              </small>
                            )}
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="form-group mb-3">
                            <label className="form-label">
                              {t("Confirm password")}
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="confirmPassword"
                              name="confirmPassword"
                              onChange={(event) =>
                                setConfirmPassword(event.target.value)
                              }
                            />
                            {confirmPasswordError && (
                              <small className="text-danger">
                                {confirmPasswordError}
                              </small>
                            )}
                          </div>
                        </Col>
                        <Col md={12} className="text-end">
                          {loading ? (
                            <Loader />
                          ) : (
                            <button
                              className="userbutton mt-3"
                              onClick={(e) => ChangePassword(e)}
                            >
                              {t("Change")}
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                {activePlan && (
                  <Col md={12} className="mb-4">
                    <Card>
                      <Card.Body>
                        <div className="d-flex flex-md-row flex-column gap-md-0 gap-4 align-items-center justify-content-between">
                          <h3 className="m-0">
                            {t(activePlan.name)}/{t(activePlan.duration)}
                          </h3>
                          {activePlan.isCustom === true ? (
                            ""
                          ) : (
                            <>
                              {!!parseInt(activePlan.price) && (
                                <Button
                                  variant="danger"
                                  className="userbutton bg-danger"
                                  onClick={(e) => DeletePlan(e)}
                                >
                                  {t("CANCEL NOW")}
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                        <div className="mt-4">
                          <div className="plan_box">
                            <div className="ribbon">
                              <span className="rib">{t("Current Plan")}</span>
                            </div>
                            <div className="name">
                              <strong>{t(activePlan.description)}</strong>
                            </div>
                            <div className="prices">
                              <h1>$ {t(activePlan.price)}</h1>
                            </div>
                            <div className="list">
                              <ul>
                                <li>
                                  <span>
                                    {activePlan.maxBotCount} {t("Bots")}
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    {activePlan.maxQuestionCount}{" "}
                                    {t("Questions")}
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    {activePlan.maxUrlCount} {t("Scan URLS")}
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    {activePlan.maxFileCount}{" "}
                                    {t("File Uploads")}
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    {activePlan.maxFileSize} MB
                                    {t(" Size per file")}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <UserPlanDelete
        show={deleteModalShow}
        onHide={() => {
          getActivePlan();

          setDeleteModalShow(false);
        }}
      />
    </>
  );
};

export default MyProfile;
