import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddClient = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [is_active, setActive] = useState("");
 
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const onChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "website":
        setWebsite(value);
        break;
      default:
        break;
    }
  };
  const saveUser = async (e) => {
    e.preventDefault();
    if (name && email && phone && company && website) {
      let password = "Chat_bot_123";
      await axios
        .post(`${API_URL}/${API_VERSION}/auth/register`, {
          name,
          password,
          email,
          phone,
          company,
          website,
          is_active
        })
        .then((response) => {
          if (response.statusText === "Created") {
            navigate("/admin/clients");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <section className="client_main">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={6} xl={6} lg={8} md={10} sm={12}>
              <div className="boxwrap">
                <h1>Add Client</h1>
                <p className="mb-3">Enter the information for your Bots.</p>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="">
                    <i className="fa-solid fa-user fa-fw"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Full name"
                    onChange={onChange}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="">
                    <i className="fa-solid fa-envelope fa-fw"></i>
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                    name="email"
                    onChange={onChange}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="">
                    <i className="fa-solid fa-mobile-screen-button fa-fw"></i>
                  </span>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={onChange}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="">
                    <i className="fa-solid fa-building fa-fw"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    placeholder="Company"
                    name="company"
                    onChange={onChange}
                  />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="">
                    <i className="fa-solid fa-globe"></i>
                  </span>
                  <input
                    type="url"
                    className="form-control"
                    id="website"
                    placeholder="Website"
                    name="website"
                    onChange={onChange}
                  />
                </div>
                <div className="text-start mb-4">
                  <input
                    type="button"
                    value="Add Client"
                    className="button px-md-4 py-md-3"
                    onClick={saveUser}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddClient;
