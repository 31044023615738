import React, { useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserUploadDoc = ({ onDrop }) => {
  const acceptedFileTypes = [".pdf", ".doc", ".docx", ".txt"];
  const [loading, setLoading] = useState(false);
  const [fileSize, setFileSize] = useState();
  const { t } = useTranslation();
  let user = localStorage.getItem("id");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [type, setType] = useState("");
  const [size, setSize] = useState(0);

  const [maxFileSize, setMaxFileSize] = useState(0);
  const [maxFileCount, setMaxFileCount] = useState(0);

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [fileError, setFileError] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      let _type = "doc";
      if (selectedFile.type === "application/pdf") {
        _type = "pdf";
      }
      setType(_type);
      setFileName(selectedFile.name);
      setSize(selectedFile.size);
    } else {
      setFileError(
        t("Please select a valid document file (PDF, DOC, DOCX, etc.).")
      );
      event.target.value = null;
    }
  };

  const saveFile = () => {
    let isValid = true;

    if (!name) {
      setNameError(t("Title is required."));
      isValid = false;
    } else {
      setNameError("");
    }

    if (!description) {
      setDescriptionError(t("Description is required."));
      isValid = false;
    } else {
      setDescriptionError("");
    }

    if (!file) {
      setFileError(t("File is required."));
      isValid = false;
    } else if (size > maxFileSize) {
      setFileError(t("File size exceeds the maximum allowed size."));
      isValid = false;
    } else {
      setFileError("");
    }

    if (isValid) {
      setLoading(true);
      axios
        .post(
          `${API_URL}/${API_VERSION}/user_doc`,
          {
            name,
            description,
            fileName,
            file,
            user,
            type,
            size,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          navigate(`/user-doc`);
        })
        .catch((error) => {
          setLoading(false);
          alert(t("Something went wrong."));
        });
    }
  };
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);

    // Add your validation logic here
    if (!newName) {
      setNameError(t("Title is required."));
    } else {
      setNameError("");
    }
  };

  // Handle real-time validation for the Description textarea
  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setDescription(newDescription);

    // Add your validation logic here
    if (!newDescription) {
      setDescriptionError(t("Description is required."));
    } else {
      setDescriptionError("");
    }
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/${API_VERSION}/user_doc/user_remaining_doc/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setMaxFileSize(
          parseFloat((response.data.maxFileSize * 1024 * 1024).toFixed(2))
        );
        setMaxFileCount(parseInt(response.data.maxFileCount));
      })
      .catch((error) => {
        alert(t("Something went wrong."));
      });

    axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setFileSize(response.data.plan.maxFileSize);
          // console.log(response.data)
        }
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        //setMessage(t("Something went wrong"));
        //setShowAlert(true);
        return false;
      });
  }, [user, API_URL, API_VERSION]);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
    maxSize: maxFileSize,
    maxFiles: maxFileCount,
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const lists = acceptedFiles.map((list) => (
    <li key={list.path}>
      {list.path} - {list.size} bytes
    </li>
  ));

  return (
    <section className="client_main">
      <Container>
        <Row className="justify-content-center">
          <Col xxl={6} xl={6} lg={6} md={8} sm={10}>
            <div className="boxwrap">
              <h1>{t("Upload Document")}</h1>

              <div className="form-group mb-3">
                <label className="form-label">{t("Title")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={(event) => handleNameChange(event)}
                />
                <div className="text-danger">{nameError}</div>
              </div>

              <div className="form-group mb-3">
                <label className="form-label">{t("Description")}</label>
                <textarea
                  className="form-control"
                  aria-label="With textarea"
                  onChange={(event) => handleDescriptionChange(event)}
                ></textarea>
                <div className="text-danger">{descriptionError}</div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="form-group mb-3">
                    <label className="form-label" for="docFile">
                      {t("Upload")}
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docFile"
                      accept=".pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={handleFileSelection}
                    />
                    <div className="text-danger">{fileError}</div>
                  </div>
                  <div
                    class="alert alert-primary d-flex align-items-center"
                    role="alert"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-info-circle-filled"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#05204a"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                        stroke-width="0"
                        fill="currentColor"
                      />
                    </svg>
                    <div className="ms-2">
                      {t("Please upload the pdf, txt, doc, docx file only*")}
                    </div>
                  </div>

                  <div className="text-start mb-4">
                    <input
                      type="button"
                      value={t("SUBMIT")}
                      className="userbutton px-4 py-3"
                      onClick={() => saveFile()}
                    />
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UserUploadDoc;
