import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";

const UserBotDelete = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const[loading,setLoading]=useState(false)
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  function UserBotDelete(id) {
    setLoading(true)
    axios
      .delete(`${API_URL}/${API_VERSION}/bot/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false)
        setType(t("Success"));
        setMessage(t("Your bot is deleted."));
        setShowAlert(true);

        props.onHide();
        setTimeout(() => {
          navigate(`/user-bots`);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false)
        setType("Danger");
        setMessage(t("Something went to wrong."));
        setShowAlert(true);
      });
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? <Loader/> :
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Delete Chatbot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              "Are you sure you want to delete your Chatbot?"
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
        
          <Button
            className="userbutton bg-danger"
            onClick={() => {
              UserBotDelete(props.deleteBotId);
            }}
          >
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>}
    </>
  );
};

export default UserBotDelete;
