// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loader.css */
.loader-container{display: flex;justify-content: center;align-items: center;position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);background-color: rgba(0, 0, 0, 0.4);
width: 100vw;height: 100vh;}
.loader-container .loader {width: 80px;height: 80px;color:#FFFFFF;background:linear-gradient(currentColor 0 0),linear-gradient(currentColor 0 0),linear-gradient(currentColor 0 0),linear-gradient(currentColor 0 0);background-size: 42px 42px;background-repeat:no-repeat;animation: sh5 1.5s infinite cubic-bezier(0.3,1,0,1);}
@keyframes sh5 {
   0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
   33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:120px;height: 120px}
   66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:120px;height: 120px}
   100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}`, "",{"version":3,"sources":["webpack://./src/Component/Loader.css"],"names":[],"mappings":"AAAA,eAAe;AACf,kBAAkB,aAAa,CAAC,uBAAuB,CAAC,mBAAmB,CAAC,eAAe,CAAC,SAAS,CAAC,QAAQ,CAAC,gCAAgC,CAAC,oCAAoC;AACpL,YAAY,CAAC,aAAa,CAAC;AAC3B,2BAA2B,WAAW,CAAC,YAAY,CAAC,aAAa,CAAC,kJAAkJ,CAAC,0BAA0B,CAAC,2BAA2B,CAAC,oDAAoD,CAAC;AACjU;GACG,MAAM,sDAAsD;GAC5D,MAAM,sDAAsD,CAAC,WAAW,CAAC,aAAa;GACtF,MAAM,sDAAsD,CAAC,WAAW,CAAC,aAAa;GACtF,MAAM,sDAAsD;AAC/D","sourcesContent":["/* Loader.css */\n.loader-container{display: flex;justify-content: center;align-items: center;position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);background-color: rgba(0, 0, 0, 0.4);\nwidth: 100vw;height: 100vh;}\n.loader-container .loader {width: 80px;height: 80px;color:#FFFFFF;background:linear-gradient(currentColor 0 0),linear-gradient(currentColor 0 0),linear-gradient(currentColor 0 0),linear-gradient(currentColor 0 0);background-size: 42px 42px;background-repeat:no-repeat;animation: sh5 1.5s infinite cubic-bezier(0.3,1,0,1);}\n@keyframes sh5 {\n   0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}\n   33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:120px;height: 120px}\n   66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:120px;height: 120px}\n   100% {background-position: 100% 0,100% 100%,0    100%,0 0   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
