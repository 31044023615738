import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Container, Row, Button, Card, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import PaymentHistoryModel from "./Modal/PaymentHistory";
import Loader from "../Loader";

export default function PaymentHistory() {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [modalShow, setModalShow] = useState(false);
  const [hisData, setHisData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loader, setLoader] = useState(false);
  const columns = [
    {
      name: "Name",
      selector: "user.name",
    },
    {
      name: "Plan",
      selector: "plan.name",
    },
    {
      name: "Price",
      selector: "price",
    },
    {
      name: "Status",
      selector: "status",
    },
    {
      name: "History",
      cell: (row) => {
        row.paymentHistory.sort((a, b) => new Date(b.ts) - new Date(a.ts));
        return (
          <>
            <p className="d-flex flex-wrap gap-2">
              <Button
                variant="success"
                onClick={(e) => openUserPaymentHistory(e, row.paymentHistory)}
              >
                History
              </Button>
            </p>
          </>
        );
      },
    },
    {
      name: "Date",
      selector: "",
      cell: (row) => {
        if (row.createdAt) {
          return dateFormate(row.createdAt);
        }
      },
    },
  ];

  function dateFormate(_date_) {
    const date = new Date(_date_);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  function openUserPaymentHistory(e, paymentHistory) {
    e.preventDefault();
    setHisData(paymentHistory);
    setModalShow(true);
  }
  function fetchHistory() {
    let setUserUrl = `${API_URL}/${API_VERSION}/users/payment-history?sortBy=createdAt&page=${page}&limit=${countPerPage}`;
    if (searchValue) {
      setUserUrl += `&status=${searchValue}`;
    }
    axios
      .get(setUserUrl, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setHistory(response.data);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }
  useEffect(() => {
    fetchHistory();
  }, [page]);
  return (
    <>
      <section className="clients_main">
        <Container fluid>
          <Row>
            {loader ? (
              <Loader />
            ) : (
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <div className="head_title">
                        <h3 className="mb-0">Payment history</h3>
                      </div>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <input
                          type="text"
                          className="form-control"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button
                          className="button"
                          onClick={() => fetchHistory()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="client_table">
                      <DataTable
                        columns={columns}
                        data={history.docs}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={history.totalDocs}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <PaymentHistoryModel
          show={modalShow}
          payment={hisData}
          onHide={() => {
            setHisData([]);
            setModalShow(false);
          }}
        />
      </section>
    </>
  );
}
