import React, { useEffect, useRef, useState } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Col, Container, Row } from "react-bootstrap";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../Loader";
import i18next from "i18next";
import i18n from "i18next";
import ReactTost from "../../ReactTost";
import { initReactI18next } from "react-i18next";
const UserChatbot = () => {
  const [type, setType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const handleFocus = () => {
    document.body.style.zoom = "100%";
  };
  const handleBlur = () => {
    document.body.style.zoom = null;
  };
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageLimitError, setMessageLimitError] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const user = localStorage.getItem("id");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const chatContainerRef = useRef(null);
  const chatMessageRef = useRef(null);

  const [sid, setSid] = useState();
  const [message, setMessage] = useState();
  const [mesg, setMesg] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [chatHistoryData, setChatHistoryData] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const [color, setColor] = useState("#05204a");
  const [name, setName] = useState();
  const [initialMsg, setInitialMsg] = useState();
  const [messageToChat, setMessageToChat] = useState(false);
  const [suggestedQuestion, setSuggestedQuestion] = useState([]);
  const [messageType, setMessageType] = useState();
  const [messageCount, setMessageCount] = useState(0);
  const [messageTime, setMessageTime] = useState(0);
  const [updateId, setUpdateId] = useState(0);
  const [limit, setLimit] = useState();
  const [seconds, setSeconds] = useState();
  const [errorDisplay, setErrorDisplay] = useState();
  const [topHeader, setTopHeader] = useState("#05204a");
  const [topHeaderFont, setTopHeaderFont] = useState("#05204a");
  const [questionBubble, setQuestionBubble] = useState();
  const [questionBubbleFont, setQuestionBubbleFont] = useState();
  const [answerBubble, setAnswerBubble] = useState("#05204a");
  const [answerBubbleFont, setAnswerBubbleFont] = useState("#05204a");
  const [sendButton, setSendButton] = useState("#05204a");
  const [passMessage, setPassMessage] = useState(false);
  const [botDeactiveMessage, setBotDeactiveMessage] = useState("");
  const [canSendMessage, setCanSendMessage] = useState(true);
  const [preferLanguage, setPreferLanguage] = useState("");
  const [checkLastMessageLimit, setCheckLastMessageLimit] = useState(false);

  const [allError, setAllError] = useState({
    English: {
      PLAN_IS_DEACTIVATED:
        "The ChatBot is disabled. Please contact our support at suipport@chatdocx.ai",
      PER_USER_LIMIT:
        "The question limit has been reached for today. Please contact our support.",
      PER_USER_LIMIT_BY_ADMIN:
        "The question limit has been reached for today. Please contact our support.",
      BOT_LIMIT:
        "The question limit has been reached for today. Please contact our support."
    },
    Deutsch: {
      PLAN_IS_DEACTIVATED:
        "Der ChatBot is insaktiv. Bitte kontaktiere support@chatdocx.ai",
      PER_USER_LIMIT:
        "Das Frage-Limit für heute würde erreicht. Bitte den Support kontaktieren.",
      PER_USER_LIMIT_BY_ADMIN:
        "Das Frage-Limit für heute würde erreicht. Bitte den Support kontaktieren.",
      BOT_LIMIT:
        "Das Frage-Limit für heute würde erreicht. Bitte den Support kontaktieren."
    }
  });

  async function userQuestion(_messageText = null) {
    const limitCheckAns = await CheckUSerMessageLimit();
    if (
      !limitCheckAns ||
      limitCheckAns === false ||
      limitCheckAns === "false"
    ) {
      return false;
    }
    chatMessageRef.current.value = " ";
    let _message = message;
    if (_messageText) {
      _message = _messageText;
    }
    let isNew = 1;
    let isKey = chatHistoryData.length;
    let __msg__ = "";
    await fetchEventSource(
      `${process.env.REACT_APP_STRING_CHAT_URL}/bot/query-stream/${id}?text=${_message}&sid=${sid}`,
      {
        method: "GET",
        headers: {
          Accept: "text/event-stream"
        },
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          const messageText = JSON.parse(event.data).token;
          if (messageText !== "[END]") {
            __msg__ = __msg__ + messageText.replace(/\n/g, "<br>");
            const urlRegex = /(https?:\/\/[^\s,;'"`<>\])}]+)/g;
            const parts = __msg__.split(urlRegex);
            let ___msg___ = "";
            parts.map((part, index) => {
              if (part.match(urlRegex)) {
                const url = part.match(urlRegex)[0];
                const punctuation = /[.,'"()]+$/;
                let cleanedUrl = url;
                const match = url.match(punctuation);
                let lastPunctuation = "";
                if (match) {
                  lastPunctuation = match[0];
                  cleanedUrl = url.slice(0, -1);
                }

                // Handle the URL
                let formattedUrl = `
                  <a
                    style="color: ${answerBubbleFont}"
                    target="_blank"
                    rel="noopener noreferrer"
                    href=${cleanedUrl}
                  >
                    ${cleanedUrl}
                  </a>
                `;

                if (match) {
                  formattedUrl += lastPunctuation;
                }

                ___msg___ += formattedUrl;
              } else {
                ___msg___ += `${part}`;
              }
            });

            const userMessage = (
              <li
                key={Date.now()}
                className="sender chatGPTreplay"
                style={{ background: answerBubble, color: answerBubbleFont }}
              >
                <div className="cont">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ___msg___
                    }}
                  ></span>
                </div>
              </li>
            );
            if (isNew) {
              isNew = false;
              setChatHistoryData((prevHistory) => [
                ...prevHistory,
                userMessage
              ]);
            } else {
              setChatHistoryData((prevHistory) => {
                const updatedHistory = [...prevHistory];
                updatedHistory[parseInt(isKey + 1)] = userMessage;
                return updatedHistory;
              });
            }
            setMessage("");
          }
        },
        onclose() {
          console.log("Connection closed by the server");
          CheckUSerMessageLimit();
        },
        onerror(err) {
          console.log("There was an error from server", err);
        }
      }
    );
  }

  async function callQuestion() {
    chatMessageRef.current.value = "";
    if (passMessage) {
      setIsDisable(() => true);
      if (sid && message) {
        const userMessage = (
          <li
            className="repaly userInput"
            style={{ background: questionBubble }}
          >
            <div className="cont" style={{ color: questionBubbleFont }}>
              <span>{message}</span>
            </div>
          </li>
        );
        setChatHistoryData((prevHistory) => [...prevHistory, userMessage]);
        setMessageToChat(false);
        await userQuestion();
        setIsDisable(() => false);
      }
    }
    chatMessageRef.current.value = "";
  }

  async function sendMessageAndUpdateChat(messageText, background, textColor) {
    if (messageText && sid) {
      const userMessage = (
        <li
          className="repaly userInput"
          style={{ background, color: textColor }}
        >
          <div className="cont">
            <span>{messageText}</span>
          </div>
        </li>
      );
      setChatHistoryData((prevHistory) => [...prevHistory, userMessage]);

      setMessageToChat(false);
      await userQuestion(messageText);
      setIsDisable(false);
      chatMessageRef.current.value = "";
      // chatMessageRef.current.focus();
    }
  }

  async function handleKeyPress(e) {
    e.preventDefault();
    if (botDeactiveMessage) {
      // If bot is not active, do not send the message
      return;
    }
    if (passMessage) {
      setMessageToChat(true);
      await sendMessageAndUpdateChat(
        message,
        questionBubble,
        questionBubbleFont
      );
    }
    chatMessageRef.current.blur();
  }

  const Scroll = () => {
    const { offsetHeight, scrollHeight, scrollTop } = chatContainerRef.current;
    if (scrollHeight <= scrollTop + offsetHeight + 100) {
      chatContainerRef.current?.scrollTo(0, scrollHeight);
    }
  };

  async function CheckUSerMessageLimit() {
    if (!sid && !localStorage.getItem(id)) {
      return false;
    }
    try {
      const messageCountResponse = await axios.post(
        `${API_URL}/${API_VERSION}/bot/checkMessageLimit/${id}`,
        {
          sID: sid ? sid : localStorage.getItem(id),
          time: messageTime
        }
      );
      setCheckLastMessageLimit(messageCountResponse.data.limit);
      setPassMessage(messageCountResponse.data.ans);
      return messageCountResponse.data.ans;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // setType("Danger");
        // setMesg(ERROR[preferLanguage][error.response.data.message]);
        // setShowAlert(true);
        const _userMessage = (
          <li
            className="sender chatGPTreplay"
            style={{
              background: answerBubble
            }}
          >
            <div className="cont">
              <span
                style={{
                  color: answerBubbleFont
                }}
              >
                {allError[preferLanguage][error.response.data.message]}
              </span>
            </div>
          </li>
        );
        setChatHistoryData((_prevHistory) => [..._prevHistory, _userMessage]);
      }
    }
  }

  function resetChatUi(questionBubble, questionBubbleFont) {
    const _userMessage = (
      <li
        className="sender chatGPTreplay"
        style={{ background: answerBubble, color: answerBubbleFont }}
      >
        <div className="cont" style={{ color: answerBubbleFont }}>
          <span>{initialMsg}</span>
        </div>
      </li>
    );
    setChatHistoryData([]);
    setChatHistoryData((_prevHistory) => [..._prevHistory, _userMessage]);
  }

  useEffect(() => {
    if (isFirst && id) {
      setIsFirst(false);
      axios
        .get(`${API_URL}/${API_VERSION}/bot/chatbot/${id}`)
        .then((response) => {
          if (response.data) {
            setMessageCount(response.data.settings.messageCount);
            setMessageTime(response.data.settings.messageLimit);
            const _preferLanguage = response.data.settings.preferLanguage
              ? response.data.settings.preferLanguage
              : "English";
            setPreferLanguage(() => _preferLanguage);
            if (response.data.settings.limitMessage) {
              window.CheckErrorMessage = response.data.settings.limitMessage;
              setAllError((prevErrors) => ({
                ...prevErrors,
                English: {
                  ...prevErrors.English,
                  PER_USER_LIMIT: response.data.settings.limitMessage
                },
                Deutsch: {
                  ...prevErrors.Deutsch,
                  PER_USER_LIMIT: response.data.settings.limitMessage
                }
              }));
            }

            if (response.data.isBotActive !== "1") {
              setBotDeactiveMessage(t("Your bot is not active"));
              setCanSendMessage(false);
            } else {
              setCanSendMessage(true);
            }
            if (response.data.chatInterface) {
              setMessageType(response.data.chatInterface.messageType);
              setColor(response.data.chatInterface.color);
              setInitialMsg(response.data.chatInterface.initialMsg);
              setName(response.data.chatInterface.name);
              setTopHeader(response.data.chatInterface.topHeader);
              setTopHeaderFont(response.data.chatInterface.topHeaderFont);
              setAnswerBubble(response.data.chatInterface.answerBubble);
              setAnswerBubbleFont(response.data.chatInterface.answerBubbleFont);
              setQuestionBubble(response.data.chatInterface.questionBubble);
              setQuestionBubbleFont(
                response.data.chatInterface.questionBubbleFont
              );
              //setSeconds(response.data.chatInterface.sendButton);
              setSendButton(response.data.chatInterface.sendButton);

              if (response.data.chatInterface.suggestedMsg) {
                setSuggestedQuestion(
                  response.data.chatInterface.suggestedMsg.split("\n")
                );
              }

              const _userMessage = (
                <li
                  className="sender chatGPTreplay"
                  style={{
                    background: response.data.chatInterface.answerBubble
                  }}
                >
                  <div className="cont">
                    <span
                      style={{
                        color: response.data.chatInterface.answerBubbleFont
                      }}
                    >
                      {response.data.chatInterface.initialMsg}
                    </span>
                  </div>
                </li>
              );
              setChatHistoryData([]);
              setChatHistoryData((_prevHistory) => [
                ..._prevHistory,
                _userMessage
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (preferLanguage) {
      axios
        .get(`${API_URL}/${API_VERSION}/bot/init/${id}`)
        .then((response) => {
          if (response.data) {
            if (response.data.sid) {
              localStorage.setItem(id, response.data.sid);
              setSid(response.data.sid);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${API_URL}/${API_VERSION}/rateLimit`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          console.log(response);
          if (response.data.length) {
            setLimit(response.data[0].maxQuestion);
            setSeconds(response.data[0].msgLimit);
            setErrorDisplay(response.data[0].msgErr);
            if (window.CheckErrorMessage) {
              setAllError((prevErrors) => ({
                ...prevErrors,
                English: {
                  ...prevErrors.English,
                  PER_USER_LIMIT: response.data[0].msgErr
                },
                Deutsch: {
                  ...prevErrors.Deutsch,
                  PER_USER_LIMIT: response.data[0].anotherErr
                }
              }));
            }
            setUpdateId(response.data[0].id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [API_URL, API_VERSION, id, isFirst, preferLanguage]);

  useEffect(() => {
    if (messageToChat && message) {
      callQuestion();
    }
  }, [messageToChat, message]);

  useEffect(() => {
    if (preferLanguage) {
      CheckUSerMessageLimit();
    }
  }, [sid, preferLanguage]);

  useEffect(() => {
    Scroll();
  }, [chatHistoryData]);

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMesg("");
        }}
        alertClass={type}
        alertMessage={mesg}
        alertDo={showAlert}
      />
      <section className="chating_box public_chatbot">
        {botDeactiveMessage && (
          <h3 style={{ color: "red" }}>{botDeactiveMessage}</h3>
        )}
        <div className="chatingwrap">
          <div className="chating_head" style={{ background: topHeader }}>
            <div className="brand" style={{ color: topHeaderFont }}>
              {name}
            </div>
            <div
              className="refresh"
              onClick={() => resetChatUi(answerBubble, answerBubbleFont)}
            >
              <i className="fa-solid fa-rotate"></i>
            </div>
          </div>
          <div className="chating_area" ref={chatContainerRef}>
            {loading ? <Loader /> : <ul>{chatHistoryData}</ul>}
          </div>

          <div className="sugbox">
            {suggestedQuestion &&
              suggestedQuestion.map((question) => {
                return (
                  <span
                    onClick={(e) => {
                      if (botDeactiveMessage) {
                        // If bot is not active, do not send the message
                        return;
                      }
                      if (passMessage) {
                        setMessageToChat(true);
                        setMessage(e.target.textContent);
                        sendMessageAndUpdateChat(
                          e.target.textContent,
                          questionBubble,
                          questionBubbleFont
                        );
                      }
                    }}
                  >
                    {question}
                  </span>
                );
              })}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="chating_foot">
              <form onSubmit={(e) => handleKeyPress(e)}>
                <input
                  type="text"
                  className="form-control"
                  aria-label="message…"
                  placeholder={messageType}
                  ref={chatMessageRef}
                  disabled={isDisable}
                  onInput={(e) => {
                    if (e.target.value.length <= 2000) {
                      setMessage(e.target.value);
                      setMessageLimitError("");
                    } else {
                      setMessageLimitError(t("Please write a shorter message"));
                    }
                  }}
                  maxLength={2000}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <button
                  style={{ background: sendButton }}
                  type="button"
                  className="button"
                  onClick={(e) => {
                    callQuestion();
                  }}
                  disabled={!canSendMessage}
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </form>
              {messageLimitError && (
                <div style={{ color: "red" }}>{messageLimitError}</div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default UserChatbot;
