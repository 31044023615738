import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const UploadDoc = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [type, setType] = useState();
  const [size, setSize] = useState();

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    const allowedFileTypes = [
      "application/pdf", // PDF files
      "application/msword", // DOC files
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX files
    ];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(selectedFile);

      setType(selectedFile.type);
      setFileName(selectedFile.name);
      setSize(selectedFile.size);
    } else {
      alert("Please select a valid document file (PDF, DOC, DOCX, etc.).");
      event.target.value = null;
    }
  };
  const saveFile = () => {
    if (id && name && description && file) {
      let user = id;
      axios
        .post(
          `${API_URL}/${API_VERSION}/users/doc`,
          {
            name,
            description,
            fileName,
            file,
            user,
            type,
            size,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          navigate(`/admin/doc-list/${id}`);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };
  return (
    <>
      <section className="client_main">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={6} xl={6} lg={8} md={10} sm={12}>
              <div className="boxwrap">
                <h1>Upload Document</h1>
                <p className="mb-3">Upload your Document here.</p>
                <div className="form-group mb-3">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="">Description</label>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    onChange={(event) => setDescription(event.target.value)}
                  ></textarea>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="docFile">upload file</label>
                  <input
                    type="file"
                    className="form-control"
                    id="docFile"
                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={handleFileSelection}
                  />
                </div>
                <div className="text-start mb-4">
                  <input
                    type="button"
                    value="submit"
                    className="button px-4 py-3"
                    onClick={() => saveFile()}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UploadDoc;
