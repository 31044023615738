import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./translations/en.json";
import translationDE from "./translations/de.json";
const fallbackLng = ["en"];
const availableLanguages = ["en", "de"];
let userLang = "";

if (!localStorage.getItem("i18nextLng")) {
  userLang = navigator.language || navigator.userLanguage;
  if (userLang !== "de" && userLang !== "en") {
    userLang = "en";
    
  }
} else {
  userLang = localStorage.getItem("i18nextLng");
}
const lng = userLang;
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: lng,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
