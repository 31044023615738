import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const UserFormSide = ({ pageTitle }) => {
  const { t } = useTranslation();
  return (
    <> 
      <section className='userform_left'>
        <Container>
          <Row>
            <Col md={12}>
            <div className="section-heading">
              <a className="brand-logo" href="/"><img src="../../images/ChatDocX_logo.svg" alt="" /></a>        
              <h2>{pageTitle}</h2>
              <h4>{t('Welcome to Chatdocx')}</h4>             
            </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default UserFormSide