import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Container, Row, Button, Card, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DeleteClient from "../Modal/DeleteClient";
import axios from "axios";
import Loader from "../../Loader";

const Clients = () => {
  const [id, setId] = useState();
  const [userId, setUserId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [userDelete, setUserDelete] = useState(true);
  const [deletedId, setDeletedId] = useState("");
  const [users, setUsers] = useState([]);
  const [isActive, setActive] = useState();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const countPerPage = 10;

  const columns = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Company",
      selector: "company",
    },
    {
      name: "Plan",
      cell: (row) => {
        let planDetail = "";
        if (row.userActivePlan.length) {
          // console.log(row.userActivePlan);
          planDetail += row.userActivePlan[0].name;
        }
        if (row.paymentHistory) {
          // console.log(row.paymentHistory);
          planDetail += `(${row.paymentHistory.status})`;
        }
        return <>{planDetail}</>;
      },
    },
    {
      name: "Data",
      cell: (row) => {
        return (
          <>
            <p className="d-flex flex-wrap gap-2">
              <Link className="button" to={`/admin/doc-list/${row._id}`}>
                <i className="fa-solid fa-file"></i> Doc.
              </Link>
              <Link className="button" to={`/admin/bots/${row._id}`}>
                <i className="fa-solid fa-robot"></i> Bots
              </Link>
            </p>
          </>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <p className="d-flex flex-wrap gap-2">
              <Link className="button" to={`/admin/edit-client/${row._id}`}>
                <i className="fa-solid fa-pen-to-square"></i>
              </Link>
              {loading ? (
                <Loader />
              ) : (
                <Button
                  variant="danger"
                  onClick={() => openDeleteUserAlert(row._id)}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </Button>
              )}
            </p>
          </>
        );
      },
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            <Form>
              <Form.Check
                type="switch"
                id={row.id}
                defaultChecked={row.is_active}
                onChange={(e) => updateUser(e, row._id)}
              />
            </Form>
          </>
        );
      },
    },
    // {
    //   name: "EmailVerify",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <Form>
    //           <Form.Check
    //             type="switch"
    //             id={row._id}
    //             defaultChecked={row.isEmailVerified}
    //             onChange={(e) => emailVerified(e, row._id)}
    //           />
    //         </Form>
    //       </>
    //     );
    //   },
    // },
  ];

  function openDeleteUserAlert(id) {
    setDeletedId(id);
    setModalShow(true);
  }

  function fetchUsers() {
    let setUserUrl = `${API_URL}/${API_VERSION}/users?role=user&page=${page}&limit=${countPerPage}`;
    if (searchValue) {
      setUserUrl += `&status=${searchValue}`;
    }
    setLoading(true);
    axios
      .get(setUserUrl, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
        console.log(response.data);
        setPage(response.data.page);
        // setActive(response.data.results.is_active);
        const _users = response.data.docs.map((id) => id);

        setId(_users);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  // function getActivePlan() {
  //   axios
  //     .get(`${API_URL}/${API_VERSION}/user/active-plan/${id}`, {
  //       headers: {
  //         accept: "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setActivePlan(response.data.plan);
  //     })
  //     .catch((error) => {});
  // }

  useEffect(() => {
    fetchUsers();
    // getActivePlan();
  }, [page]);

  function updateUser(e, id) {
    const newIsActive = e.target.checked;
    // axios
    //   .get(`${API_URL}/${API_VERSION}/userStatus/${id}`, {
    //     headers: {
    //       accept: "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     setActive(response.data.is_active);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    setId(id);
    // setActive(newIsActive);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/userStatus/${id}`,
        {
          newIsActive,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setActive(newIsActive);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function emailVerified(e, id) {
    const isVerify = e.target.checked;

    setId(id);
    setIsEmailVerified(isVerify);
    axios
      .post(
        `${API_URL}/${API_VERSION}/users/update-verify-email-status/${id}`,
        {
          isEmailVerified: isVerify,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setIsEmailVerified(isVerify);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function deleteUser(uId) {
    if (uId) {
      await axios
        .delete(`${API_URL}/${API_VERSION}/user/active-plan/${uId}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {})
        .catch((error) => {});
      setLoading(true);
      await axios
        .delete(`${API_URL}/${API_VERSION}/users/${uId}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setUserDelete(true);
          setModalShow(true);
          navigate("/admin/clients");
          fetchUsers();
          setModalShow(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="clients_main">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <div className="head_title">
                        <h3 className="mb-0">Clients</h3>
                      </div>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <input
                          type="text"
                          className="form-control"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button className="button" onClick={() => fetchUsers()}>
                          Search
                        </button>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="client_table">
                      <DataTable
                        columns={columns}
                        data={users.docs}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={users.totalDocs}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      <DeleteClient
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={() => deleteUser(deletedId)}
      />
    </>
  );
};

export default Clients;
