import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ChatBotData from "./ChatBotData";
import ChatBotSetting from "./ChatBotSetting";
import ChatBotInterface from "./ChatBotInterface";
import ChatBotSources from "./ChatBotSources";
import ChatBotEmbed from "./ChatBotEmbed";
import ChatBotDelete from "./ChatBotDelete";
import ChatHistory from "../../User/ChatHistory/ChatHistory";
import ChatBotShare from "./ChatBotShare";
const ChatBot = () => {
  return (
    <>
      <section className="chatbotdata">
        <Container>
          <Row>
            <Col>
              <Tabs>
                <TabList className='d-flex align-items-center justify-content-center'>
                  <Tab>Chatbot</Tab>
                  <Tab>Chat history</Tab>
                  <Tab>Settings</Tab>
                  <Tab>Chat interface</Tab>
                  <Tab>Sources</Tab>
                  <Tab>Embed on site</Tab>
                  <Tab>Share</Tab>
                  <Tab>Delete</Tab>
                </TabList>

                <TabPanel>
                  <ChatBotData />
                </TabPanel>
                <TabPanel>
                  <ChatHistory/>
                </TabPanel>
                <TabPanel>                  
                  <ChatBotSetting />
                </TabPanel>
                <TabPanel>                  
                  <ChatBotInterface />
                </TabPanel>
                <TabPanel>                  
                  <ChatBotSources />
                </TabPanel>
                <TabPanel>
    
                  <ChatBotEmbed />
                </TabPanel>
                <TabPanel>
                  <ChatBotShare/>
                </TabPanel>
                <TabPanel>
                  <ChatBotDelete />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ChatBot;
