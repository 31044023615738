import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import ReactTost from "../../ReactTost";
const UserChatBotEmbed = () => {
  const [shareUrl, setShareUrl] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const codeSnippetIframe = `<iframe src="${shareUrl}" scrolling="no" width="100%"  style="height: 100%; min-height: 497px" frameborder="0"></iframe>`;
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const codeSnippetScript = `
  <script type='text/javascript'>
  (function () {
    var s1 = document.createElement('script');
    var s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = '${process.env.REACT_APP_URL}/chatbot/main.js?botId=${id}';
    s1.id = 'chatdoxtag';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s1.setAttribute('data-ip', window.location.host);
    s0.parentNode.insertBefore(s1, s0);
  })();
  </script>`;
  // const codeSnippetScript = `<script type='text/javascript'> (function () { var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0]; s1.async = true; s1.src = 'https://app.chatdocx.ai/chatbot/main.js?botId=${id}'; s1.id = 'chatdoxtag'; s1.charset = 'UTF-8'; s1.setAttribute('crossorigin', '*'); s0.parentNode.insertBefore(s1, s0); })(); </script>`;

  useEffect(() => {
    if (id) {
      setShareUrl(`${process.env.REACT_APP_URL}/iframe-chatbot/${id}`);
    }
  }, [id]);
  function CopyData(e) {
    e.preventDefault();
    copy(codeSnippetIframe);
    setType("Success");
    setMessage(t("Text copied to clipboard!"));
    setShowAlert(true);
  }
  function CopyDataScript(e) {
    e.preventDefault();
    copy(codeSnippetScript);
    setType("Success");
    setMessage(t("Text copied to clipboard!"));
    setShowAlert(true);
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <section className="embed_box">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="embed_wrap">
                <h2>{t("Embed on site")}</h2>
                <p></p>

                <div className="codewrap">
                  <h4>
                    {t("Iframe")}
                    <Button
                      className="userbutton my-1 float-end"
                      variant="danger"
                      onClick={(e) => {
                        CopyData(e);
                      }}
                    >
                      {t("Copy")}
                    </Button>
                  </h4>
                  <span>
                    {t(
                      "To add the chatbot any where on your website, add this iframe to your html code"
                    )}
                  </span>
                  <div className="code_box">
                    <code>{codeSnippetIframe}</code>
                  </div>

                  <hr className="my-5" />

                  <h4>
                    {t("Script")}{" "}
                    <Button
                      className="userbutton mb-5 float-end"
                      variant="danger"
                      onClick={(e) => {
                        CopyDataScript(e);
                      }}
                    >
                      {t("Copy")}
                    </Button>
                  </h4>

                  <span>
                    {t(
                      "To add a chat bubble to the bottom right of your website add this script tag to your html"
                    )}
                  </span>

                  <div className="code_box">
                    <code>{codeSnippetScript}</code>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserChatBotEmbed;
