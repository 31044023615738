import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import axios from "axios";
const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [botCount, setBotCount] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  useEffect(() => {
    axios
      .get(`${API_URL}/${API_VERSION}/count`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data) {
          setUserCount(response.data.total_user);
          setBotCount(response.data.total_bot);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [API_URL, API_VERSION]);
  return (
    <>
      <div className="dashboard_box">
        <Container>
          <Row>
            <Col xxl={4} xl={6}>
              <Card className="info_card">
                <Card.Body>
                  <div className="card_cont">
                    <Card.Title variant="h2">Clients</Card.Title>
                    <Card.Subtitle className="mb-2">
                      {/* Total Number of clients */}
                    </Card.Subtitle>
                    <Card.Text>
                      <strong className="display-1 fw-bold">{userCount}</strong>
                    </Card.Text>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-user-group"></i>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xxl={4} xl={6}>
              <Card className="info_card">
                <Card.Body>
                  <div className="card_cont">
                    <Card.Title>Bots</Card.Title>
                    <Card.Subtitle className="mb-2">
                      {/* Total Number of Bots */}
                    </Card.Subtitle>
                    <Card.Text>
                      <strong className="display-1 fw-bold">{botCount}</strong>
                    </Card.Text>
                  </div>
                  <div div className="icon">
                    <i className="fa-solid fa-robot"></i>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
