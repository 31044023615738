import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Card, Form } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import DeleteClientDoc from "../Modal/DeleteClientDoc";
import Loader from "../../Loader";

function DocList() {
  const { id } = useParams();
  const [document, setDocument] = useState([]);
  const [isDocActive, setIsDocActive] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [userDelete, setUserDelete] = useState(true);
  const [deletedId, setDeletedId] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const fetchDoc = () => {
      setLoader(true);
      axios
        .get(`${API_URL}/${API_VERSION}/user_doc?id=${id}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoader(false);
          setDocument(response.data.results);
          console.log(response.data)
          console.log(response.data.results.isDocActive)
          const activeDoc = response.data.results.find((bot) => bot.isDocActive === '1');
          setIsDocActive(!!activeDoc);
          setUserDelete(false);
        })
        .catch((error) => {
          setLoader(false)
          console.log(error);
        });
    };
    if (userDelete) {
      fetchDoc();
    }
  }, [id, API_URL, API_VERSION, userDelete]);

  function openDeleteUserDocAlert(id) {
    setDeletedId(id);
    setModalShow(true);
  }
  function deleteUser(uId) {

    if (uId) {
      setLoader(true);
      axios
        .delete(`${API_URL}/${API_VERSION}/users/doc/${uId}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoader(false);
          setUserDelete(true);
          setModalShow(false);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  }
  function updateUser(e, id) {
    const isDocActive = e.target.checked;
    console.log(isDocActive);
    let value="";
    if(isDocActive===true)
    {
      value="1"
    }
    else{
     value="2"
    }
    
    axios
      .patch(
        `${API_URL}/${API_VERSION}/user_doc/docStatus/${id}`,
        {
          isDocActive: value,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setIsDocActive(isDocActive);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      {loader ? <Loader /> :
        <section className="clients_main">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header as="h5">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <div className="head_title">
                        <h3 className="mb-0">Documents</h3>
                      </div>
                      <Link className="button" to={`/admin/upload-doc/${id}`}>
                    <i className="fa-solid fa-circle-plus"></i> Add Document
                  </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <table className="client_table">
                      <thead>
                        <tr className="d-none d-lg-table-row">
                          <th width="12%">Name</th>
                          <th width="23%">Description</th>
                          <th width="23%">File Name</th>
                          <th width="1%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {document.length > 0 &&
                          document.map((user, index) => {
                            return (
                              <tr className="tr" key={index}>
                                <td>
                                  <strong className="ht">Name : </strong>
                                  <p>{user.name}</p>
                                </td>
                                <td>
                                  <strong className="ht">Description : </strong>
                                  <p>{user.description}</p>
                                </td>
                                <td>
                                  <strong className="ht">File Name : </strong>
                                  <p>{user.fileName}</p>
                                  <p style={{color:'red'}}>{user.isDocActive==='0' && "File is not active"}</p>
                                </td>
                                <td>
                                  <strong className="ht">Action : </strong>
                                  <p className="d-flex gap-2">
                                    <Form>
                                      <Form.Check
                                        className="float-end"
                                        type="switch"
                                        defaultChecked={user.isDocActive==='1'?true:false}
                                       onChange={(e) => updateUser(e, user.id)}
                                      />
                                    </Form>
                                    {loader ? <Loader /> :
                                      <Button
                                        variant="danger"
                                        onClick={() => openDeleteUserDocAlert(user.id)}
                                      >
                                        <i className="fa-solid fa-trash-can"></i>
                                      </Button>}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>}
      <DeleteClientDoc
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={() => deleteUser(deletedId)}
      />
    </>
  );
}

export default DocList;
