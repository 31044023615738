import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";

import ReactTost from "../../ReactTost";

const UserChatBotShare = (props) => {
  const { t } = useTranslation();
  const [shareUrl, setShareUrl] = useState("");
  const id = props.botData.id;
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (id) {
      setShareUrl(`${process.env.REACT_APP_URL}/public-chatbot/${id}`);
    }
  }, [id]);
  function CopyData(e) {
    e.preventDefault();
    copy(shareUrl);
    setType("Success");
    setMessage(t("Text copied to clipboard!"));
    setShowAlert(true);
  }

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <section className="embed_box">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="embed_wrap">
                <h2>{t("Share chatbot")}</h2>
                <InputGroup className="mb-3">
                  <strong className="form-control">{shareUrl}</strong>
                  <Button
                    className="button"
                    variant="danger"
                    onClick={(e) => {
                      CopyData(e);
                    }}
                  >
                    {t("Copy")}
                  </Button>
                </InputGroup>
                <strong className="text-danger">{t("Warning! If you share this link, anybody can access your chatbot, independent from the private or public mode under settings. If you want to show your bot on your website, please use embeddings with iframe or with javascript.")}</strong>              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserChatBotShare;
