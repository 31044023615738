import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, InputGroup, Button } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import ReactTost from "../../ReactTost";
import DeleteUrls from "../../User/Modal/DeleteUrls";
const ChatBotSources = (props) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const { id } = useParams();
  const { t } = useTranslation();
  // const[userId,setUserId]=useState("");
  const [isUrl, setIsUrl] = useState(true);
  const [docList, setDocList] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [urlCount, setUrlCount] = useState(0);
  const [urls, setUrls] = useState([""]);
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState("");
  const [urlId, setUrlId] = useState();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  function updateDoc(docId, event) {
    const checkboxId = event.target.value;

    if (event.target.checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxId]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((id) => id !== checkboxId)
      );
    }
  }

  const handleUrlChange = (index, url, id) => {
    const updatedUrls = [...urls];
    console.log(updatedUrls);
    if (id && user) {
      let _temp = {
        user,
        url,
        id,
      };
      updatedUrls[index] = _temp;
    } else {
      updatedUrls[index] = url;
    }
    console.log(updatedUrls);
    setUrls(updatedUrls);
  };
  //delete scan url
  const deleteUrlHandler = (index, id = null) => {
    if (id) {
      setUrlId(id);
      setDeleteModalShow(true);
    } else {
      const updatedUrls = urls.filter((_, i) => i !== index);
      setUrls(updatedUrls);
    }
  };
  async function fetchUrl() {
    setLoading(true);
    await axios
      .get(`${API_URL}/${API_VERSION}/url/?id=${userId}&botId=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(async (response) => {
        setLoading(false);
        if (response.data.length) {
          response.data.map((data, index) => {
            const fetchedUrls = response.data.map((item) => item);
            setUrls(fetchedUrls);
          });
          setUrlId(response.data.id);
        } else {
          setUrls([]);
          setUrlId("");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  async function indexingDocument() {
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_URL}/${API_VERSION}/bot/index/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((_response) => {
          fetchUrl();
          resolve(true);
        })
        .catch((_error) => {
          reject(false);
        });
    });
  }
  function updateDocument(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/bot/${id}`,
        {
          docs: selectedCheckboxes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(async (response) => {
        await indexingDocument()
          .then(() => {
            setMessage("Selected documents successfully scanned.");
            setType("Success");
          })
          .catch(() => {
            setType("Danger");
            setMessage("Documents not save to gpt.");
          });
        setShowAlert(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setType("Danger");
        setMessage("Something went wrong.");
        setShowAlert(true);
      });
  }

  //scan urls
  function saveScanUrl(e, index, _tempId) {
    e.preventDefault();
    setLoading(true);
    checkingPlan()
      .then((_data_) => {
        if (urls[index]) {
          let url = [];
          url.push(urls[index]);
          let isUpdate = 0;
          if (_tempId) {
            isUpdate = _tempId;
          }
          axios
            .post(
              `${API_URL}/${API_VERSION}/url`,
              {
                url,
                user:userId,
                bot: id,
                isUpdate,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(async (response) => {
              setTimeout(async () => {
                await indexingDocument()
                  .then(() => {
                    setMessage("Url scanning success");
                    setType("Success");
                  })
                  .catch(() => {
                    setType("Danger");
                    setMessage("Url not save to gpt.");
                  });
                setShowAlert(true);
                setLoading(false);
              }, 5000);
            })
            .catch((err) => {
              setLoading(false);
              setType("Danger");
              setMessage("Something went wrong.");
              setShowAlert(true);
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }
  function checkingPlan() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response.data.user);
        console.log(typeof(user))
        setLoading(false);
        setUserId(response.data.user.id);
        console.log(response.data.user.id);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [API_URL, API_VERSION, id]);
  useEffect(() => {
    setLoading(true);
    if (userId) {
      axios
        .get(`${API_URL}/${API_VERSION}/user_doc?id=${userId}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setDocList(response.data.results);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    //for fetching user doc
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const receivedCheckboxIds = response.data.docs;
        //setUrlId(response.data.user.id)

        setSelectedCheckboxes(receivedCheckboxIds);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [API_URL, API_VERSION, user, userId]);
  useEffect(() => {
    if (isUrl && user) {
      setIsUrl(false);
      (async () => {
        await fetchUrl();
        setTimeout(async () => {
          await getUserUrlCount();
        }, 3000);
      })();
    }
  }, [API_URL, API_VERSION, user, isUrl]);

  async function getUserUrlCount() {
    setLoading(true);

    await axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setUrlCount(response.data.plan.maxUrlCount);
        }
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage("something went wrong");
        setShowAlert(true);
        return false;
      });
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <section className="sm-tabs">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <Tabs>
                <TabList className="d-flex align-items-center justify-content-center">
                  <Tab>Choose files</Tab>
                  <Tab>Scan URL</Tab>
                </TabList>
                {loading ? (
                  <Loader />
                ) : (
                  <TabPanel>
                    <div className="option_box">
                      <div
                        class="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-info-circle-filled"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                        <div className="ms-2">
                          {t("Please Add Your Document")}
                        </div>
                      </div>

                      <h4>Choose Document</h4>
                      {docList && docList.map((doc, index) => (
                          <Form.Group
                            className="mb-3"
                            //controlId={index}
                            key={doc.id}
                          >
                            {doc.isDocActive==='1'&&
                            <Form.Check
                              type="checkbox"
                              id={doc.id}
                              label={doc.name}
                              checked={selectedCheckboxes.includes(doc.id)}
                              value={doc.id}
                              onChange={(e) => updateDoc(doc.id, e)}
                            />}
                          </Form.Group>
                        ))}
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          onClick={updateDocument}
                          className="button"
                        >
                          save
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                )}
                {loading ? (
                  <Loader />
                ) : (
                  <TabPanel>
                    <div className="option_box">
                      <div
                        class="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-info-circle-filled"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#05204a"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                            stroke-width="0"
                            fill="currentColor"
                          />
                        </svg>
                        <div className="ms-2">{t("You can scan url here")}</div>
                      </div>

                      {urls && urlCount > 0 && (
                        <>
                          {Array.from({ length: urlCount }, (_, index) => {
                            let _tempUrl = "";
                            let _tempId = "";

                            if (urls[index]) {
                              _tempId = urls[index].id;
                              _tempUrl = urls[index].url;
                            }
                            return (
                              <div
                                key={index}
                                className="d-flex flex-sm-row flex-column align-items-center justify-content-between gap-2 mb-3"
                              >
                                <Form.Control
                                  type="url"
                                  placeholder="Add URL for scan"
                                  value={_tempUrl}
                                  onChange={(e) =>
                                    handleUrlChange(
                                      index,
                                      e.target.value,
                                      _tempId
                                    )
                                  }
                                />
                                {/* {_tempUrl ? (
                                <a
                                  className="userbutton"
                                  href="/somefile.txt"
                                  download
                                >
                                  <i class="fa-solid fa-download"></i>
                                </a>
                              ) : (
                                ""
                              )} */}
                                {loading ? (
                                  <Loader />
                                ) : (
                                  <button
                                    className="userbutton"
                                    onClick={(e) =>
                                      saveScanUrl(e, index, _tempId)
                                    }
                                  >
                                    {t("Scan")}
                                  </button>
                                )}
                                <Button
                                  type="button"
                                  variant="danger"
                                  onClick={() =>
                                    deleteUrlHandler(index, _tempId)
                                  }
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </Button>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </TabPanel>
                )}
              </Tabs>
            </Col>
          </Row>
        </Container>
        <DeleteUrls
          show={deleteModalShow}
          deleteScanUrlId={urlId}
          // fetch={fetchUrl}
          onHide={() => {
            indexingDocument();
            fetchUrl();
            setDeleteModalShow(false);
          }}
        />
      </section>
    </>
  );
};

export default ChatBotSources;
