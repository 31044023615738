import React, { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import UserBotDelete from "../Modal/UserBotDelete";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserChatBotDelete = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [getBots, setGetBots] = useState(true);
  const id = props.botData.id;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="embed_box">
          <Container>
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="embed_wrap">
                  <h2>{t("Delete chatbot")}</h2>
                  <p>
                    {t(
                      "Are you sure you want to delete your chatbot?"
                    )}
                  </p>
                  <button className="userbutton bg-danger"                    
                    onClick={() => {
                      setDeleteModalShow(true);
                    }}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      <UserBotDelete
        show={deleteModalShow}
        deleteBotId={id}
        onHide={() => {
          setGetBots(true);
          setDeleteModalShow(false);
        }}
      />
    </>
  );
};

export default UserChatBotDelete;
