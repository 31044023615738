import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader";

const DeleteUrls = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const botId = useParams();
  const [loading,setLoading]=useState(false);
  function DeleteUrls(id) {
    setLoading(true)
    if (id) {
      axios
        .delete(`${API_URL}/${API_VERSION}/url/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setType(t("Success"));
          setMessage(t("URL is deleted."));
          setShowAlert(true);
          props.onHide();
        })
        .catch((error) => {
          setLoading(false);
          setType("Danger");
          setMessage(t("Something went to wrong."));
          setShowAlert(true);
        });
    } else {
    }
  }
  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Delete URL")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("Are you sure you want to delete this url?")}
          </p>
        </Modal.Body>
        <Modal.Footer>
         
          {loading ?<Loader/>:
          <Button
            className="button bg-danger"
            onClick={() => {
              DeleteUrls(props.deleteScanUrlId);
            }}
          >
            {t("Delete")}
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteUrls;
