import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Success = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="info_box">
              <div className="icon text-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="100" height="100" viewBox="0 0 24 24" stroke-width="2" stroke="#05204a" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M9 12l2 2l4 -4" />
              </svg>
              </div>
              <h1 className="success-heading">{t("Subscription Successful!")}</h1>
              <p>{t("Thank you for subscribing to our service.")}</p>
              <p>{t("Your subscription is now active.")}</p>
            </div>
          </Col>
        </Row>
      </Container>      
    </>
  );
};
export default Success;
