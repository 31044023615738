import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserFormSide from "./UserFormSide";
import UserLanguage from "./UserLanguage";
import axios from "axios";
import ReactTost from "../ReactTost";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

const UserForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    email: "",
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  function forgotPassword(e) {
    e.preventDefault();
    const errors = {};

    if (!email) {
      errors.email = t("Email is required.");
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const language = localStorage.getItem('i18nextLng') === 'de' ? 'german' : 'english';

    setLoading(true);
    axios
      .post(`${API_URL}/${API_VERSION}/auth/forgot-password`, {
        language,
        email,
      })
      .then((response) => {
        setLoading(false);
        setType("Success");
        setMessage(t("Please check your email."));
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(t("We are unable to find this email in our record."));
        setShowAlert(true);
      });
  }
  function handleInputChange(field) {

    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  }
  return (
    <>
      <UserLanguage claval="" />
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="userform_wrap">
          <Container fluid>
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <UserFormSide pageTitle={t("Forgot password")} />
              </Col>
              <Col lg={6}>
                <section className="user_form">
                  <Container>
                    <Row className="row justify-content-center">
                      <Col lg={10} md={8} sm={10}>
                        <div className="boxwrap p-md-5 p-4">
                          <h2>{t("Forgot password")}</h2>
                          <p className="mb-3">
                            {t(
                              "Provide Your account's email for which you want to reset your password"
                            )}
                          </p>
                          <form action="">
                            <Row>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Email address")} <span>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    onChange={(e) => {setEmail(e.target.value)
                                    handleInputChange('email')}}
                                  />
                                  {fieldErrors.email && (
                                    <div className="error" style={{color:'red'}}>{fieldErrors.email}</div>
                                  )}
                                </div>
                              </Col>
                              <div className="text-start">
                                <input
                                  type="button"
                                  value={t("Send")}
                                  className="button"
                                  onClick={(e) => forgotPassword(e)}
                                />
                              </div>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default UserForgotPassword;
