import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserFormSide from "./UserFormSide";
import UserLanguage from "./UserLanguage";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactTost from "../ReactTost";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const UserResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const navigate = useNavigate();
  function isPasswordStrong(password) {
    // Define your password strength criteria here
    const minLength = 8;
    const maxLength = 20;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*]/.test(password); // You can add more special characters as needed
  
    return (
      password.length >= minLength &&
      password.length <= maxLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  }
  function isPasswordStrong(password) {
    // Define your password strength criteria here
    const minLength = 8;
    const maxLength = 20;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*]/.test(password); // You can add more special characters as needed
  
    return (
      password.length >= minLength &&
      password.length <= maxLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  }
  function changePassword(e) {
    e.preventDefault();
    const token = id;
    const language = localStorage.getItem('i18nextLng');
    if (!password || !confirmPassword) {
      // Handle the case where either new_password or confirm_password is empty
      setNewPasswordError(t("Password cannot be empty"));
      setConfirmPasswordError(t("Password cannot be empty"));
      return false;
    }
    if (password !== confirmPassword) {
      
      setNewPasswordError(t(" Password and confirm password must match"));
      setConfirmPasswordError(t("Password and confirm password must match"))
      return false;
    }
  
    if (!isPasswordStrong(password)) {
      
       setNewPasswordError(t("Your password must be 8-20 characters long, contain letters, numbers and special characters"));
      return false;
    }
    if (password !== confirmPassword) {
      setType("Danger");
      setMessage(t(" Password and confirm password must match"));
      setShowAlert(true);
      return false;
    }
  
    if (!isPasswordStrong(password)) {
      setType("Danger");
      setMessage(t("Your password must be 8-20 characters long, contain letters, numbers and special characters"));
      setShowAlert(true);
      return false;
    }
  
    if (password && confirmPassword && password === confirmPassword) {
      setLoading(true);
      axios
        .post(`${API_URL}/${API_VERSION}/auth/reset-password`, {
          language,
          token,
          password,
        })
        .then((response) => {
          setLoading(false);
          setType("Success");
          setMessage(t("Your password is reset."));
          setShowAlert(true);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          setType("Danger");
          setMessage(t("Something went wrong."));
          setShowAlert(true);
        });
    }
  }
  
  
  // function changePassword(e) {
  //   e.preventDefault();
  //   const token = id;
  //   const language=localStorage.getItem('i18nextLng');
  //   if (password !== confirmPassword) {
  //     setType("Danger");
  //     setMessage("Password are not match.");
  //     setShowAlert(true);
  //     return false;
  //   }
  //   if (password && confirmPassword && password === confirmPassword) {
  //     setLoading(true);
  //     axios
  //       .post(`${API_URL}/${API_VERSION}/auth/reset-password`, {
  //         language,
  //         token,
  //         password,
  //       })
  //       .then((response) => {
  //         setLoading(false);
  //         setType("Success");
  //         setMessage(t("Your password is reset."));
  //         setShowAlert(true);
  //         setTimeout(() => {
  //           navigate("/");
  //         }, 3000);
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         setType("Danger");
  //         setMessage(t("Something went wrong."));
  //         setShowAlert(true);
  //       });
  //   }
  // }
  return (
    <>
      <UserLanguage claval="" />
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="userform_wrap">
          <Container fluid>
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <UserFormSide pageTitle={t("Reset password")} />
              </Col>
              <Col lg={6}>
                <section className="user_form">
                  <Container>
                    <Row className="justify-content-center">
                      <Col xl={8} lg={10} md={10}>
                        <div className="boxwrap p-md-5 p-4">
                          <h2>{t("Reset password")}</h2>
                          <p className="mb-3">
                            {t("Your password must be 8-20 characters long, contain letters, numbers and special characters.")}
                          </p>
                          <form action="">
                            <Row>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("New password")} <span>*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                     {newPasswordError && (
            <small className="text-danger">{newPasswordError}</small>
          )}
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Repeat new password")} <span>*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                  />
                                    {confirmPasswordError && (
            <small className="text-danger">{confirmPasswordError}</small>
          )}
                                </div>
                              </Col>
                              <div className="d-flex align-items-center flex-lg-row">
                                <div className="text-start">
                                  <input
                                    type="button"
                                    value={t("Save")}
                                    className="button"
                                    onClick={(e) => changePassword(e)}
                                  />
                                </div>
                                <div className="text-end ms-auto">
                                  <input
                                    type="button"
                                    value={t("Back to login")}
                                    className="button"
                                    onClick={()=>navigate('/')}
                                  />
                                </div>
                               
                              </div>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default UserResetPassword;
