import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

const AddBot = (props) => {
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
   // console.log(props.payment);
    setHistoryData(props.payment);
  }, [props]);
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment history
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <thead>
              <th>Status</th>
              <th>Date/Time</th>
            </thead>
            <tbody>
              {historyData &&
                historyData.map((d, i) => {
                  const isoDate = new Date(d.ts);
                  const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZoneName: "short",
                  };
                  const changeDateTime = isoDate.toLocaleString(
                    "en-US",
                    options
                  );
                  return (
                    <>
                      <tr>
                        <td>
                          <div>{d.status}</div>
                        </td>
                        <td>
                          <div>{changeDateTime}</div>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBot;
